import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import {
	EventDataService,
	EventDataTagTypeEnum,
	EventDataTagValueEnum
} from 'src/app/core/services/event-data/event-data.service';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import {
	ExternalOfferNameEnum,
	IEligibleProducts,
	IExternalOffersResult,
	IMultiProductResult,
	MobileApiService
} from 'src/app/core/services/mobile-api';
import { MultiProductService, ProductTypeEnum } from 'src/app/core/services/multi-product/multi-product.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { DigitUtils } from 'src/app/core/utils/digit-utils';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';

@Component({
	selector: 'op-status-not-approved',
	templateUrl: './not-approved.component.html',
	styleUrls: ['./not-approved.component.scss']
})
export class NotApprovedComponent implements OnInit, OnDestroy {
	private _loanApp: ILoanApplication;
	get loanApp(): ILoanApplication {
		return this._loanApp;
	}

	@Input()
	set loanApp(value: ILoanApplication) {
		this._loanApp = value;
	}

	constructor(
		private multiProductService: MultiProductService,
		private tagDataService: TagDataService,
		private mobileApiService: MobileApiService,
		private eventDataService: EventDataService,
		private languageService: LanguageService
	) {}

	body: string;
	newApplicationStartDate: String;
	subTitle: string;
	title: string;
	issuingOrganizationIsOportun: boolean;
	issuingOrganizationIsMeta: boolean;

	private subscription = new Subscription();
	digitUrl: string;
	moneyLionUrl: string;

	ngOnInit(): void {
		this.body = `STATUS.${this.loanApp.applicationStatus}.body`;
		this.languageService.langChanges$.subscribe((lang) => {
			const today = new Date();
			const futureDate = new Date(today.setDate(today.getDate() + 30));
			this.newApplicationStartDate = futureDate.toLocaleDateString(lang, { month: 'long', day: 'numeric' });
		});
		this.subTitle = `STATUS.${this.loanApp.applicationStatus}.initial`;
		this.title = `STATUS.${this.loanApp.applicationStatus}.title.${this.loanApp.issuingOrganization}`;
		this.issuingOrganizationIsOportun = OrganizationUtils.isOportun(this.loanApp.issuingOrganization);
		this.issuingOrganizationIsMeta = OrganizationUtils.isMetaBank(this.loanApp.issuingOrganization);
		const multiProductSub = this.multiProductService.checkMultiProductEligibility(this.loanApp.id).subscribe({
			next: (response: IMultiProductResult) => {
				this.digitUrl = this.getDigitUrl(response);
			}
		});
		this.subscription.add(multiProductSub);

		const offerSub = this.mobileApiService.getAlternateOffers(this.loanApp.id).subscribe({
			next: (altOffers: IExternalOffersResult) => {
				this.setMoneyLionOffer(altOffers);
			},
			error: (error) => {}
		});
		this.subscription.add(offerSub);
	}

	public trackEligibilityEvents(eventLabel: string): void {
		const tealiumEvent = {
			tealium_event: 'check_multiproduct_eligible',
			event_category: 'CONSUMER_INSTALLMENT_LOAN',
			event_label: eventLabel
		};
		if (eventLabel === 'digit_offer_clicked') {
			tealiumEvent['link_href'] = this.digitUrl;
		}
		if (eventLabel === 'moneyLion_clicked') {
			tealiumEvent['link_href'] = this.moneyLionUrl;
		}

		this.tagDataService.link({}, tealiumEvent);
	}

	onMoneyLion(event: any): void {
		this.trackEligibilityEvents('moneyLion_clicked');
		this.eventDataService.logEventDataToLoanApplication({
			eventType: EventDataTagTypeEnum.moneyLionOffer,
			value1: EventDataTagValueEnum.offerClicked
		});
		window.open(this.moneyLionUrl, '_blank');
	}

	private setMoneyLionOffer(altOffers: IExternalOffersResult): void {
		if (!isEmpty(altOffers?.offers)) {
			const moneyLionOffer = altOffers.offers.find((offer) => offer.partner === ExternalOfferNameEnum.moneyLion);
			if (moneyLionOffer) {
				this.moneyLionUrl = moneyLionOffer.url;
				this.trackEligibilityEvents('moneyLion_offer_shown');
				this.eventDataService.logEventDataToLoanApplication({
					eventType: EventDataTagTypeEnum.moneyLionOffer,
					value1: EventDataTagValueEnum.offerPresented
				});
			}
		}
	}

	onDigitOffer(event: any): void {
		this.trackEligibilityEvents('digit_offer_clicked');
		window.open(this.digitUrl, '_blank');
	}

	private getDigitUrl(response: IMultiProductResult): string {
		let digit_url = null;
		if (response.success && response.multiProductEligible) {
			if (response.eligibleProducts?.length) {
				const digitProduct: IEligibleProducts = response.eligibleProducts.find(
					(item) => item.productType.toLowerCase() === ProductTypeEnum.digit
				);
				if (digitProduct) {
					this.trackEligibilityEvents('digit_eligible');
					digit_url = digitProduct.redirectUrl;
					const tealium_vid = this.tagDataService.getTeliumVisitorId();
					if (tealium_vid) {
						digit_url = DigitUtils.getDigitUrl(digit_url, tealium_vid, this.issuingOrganizationIsMeta);
					}
					this.trackEligibilityEvents('digit_offer_shown');
				}
			}
		}
		return digit_url;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
