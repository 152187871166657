<div class="plaid-link" *transloco="let t">
	<op-learn-more id="EstbCreditHistoryQ" src="/assets/images/connect-bank.svg" *ngIf="!plaidConnectForIncomeVerified">
		<div id="EstbCreditHistoryA">
			<div class="op-body-1-bold-font">
				{{ t('PLAID_CONNECT.connectYourBank') }}
			</div>
		</div>
	</op-learn-more>
	<ng-container *ngIf="plaidConnectForIncomeVerified">
		<ng-container *ngIf="!showBenefits">
			<div
				class="policy op-dark-grey"
				*ngIf="!incomeVerified"
				[ngClass]="{ 'op-mb-20': newMemberDebitCardEligibility, 'op-mb-8': !newMemberDebitCardEligibility }"
			>
				<span
					class="op-body-2-font"
					[innerHtml]="
						t(
							newMemberDebitCardEligibility
								? 'PLAID_CONNECT.mayResultInFasterProcessingTime'
								: 'PLAID_CONNECT.fasterProcessingTime'
						)
					"
				></span>
			</div>
			<ng-container *ngIf="!newMemberDebitCardEligibility; else newMemberDebitCardExperience">
				<div class="op-icon-align-body-2-green" *ngIf="showIncentive">
					<mat-icon aria-hidden="true" svgIcon="op-moneyBag"></mat-icon>
					<span
						class="op-body-1-bold-font"
						[innerHTML]="
							t(incomeVerified ? 'PLAID_CONNECT.getLoanAmountIncreased' : 'PLAID_CONNECT.getLoanAmountIncreasedStar')
						"
					>
					</span>
				</div>
				<div class="policy op-mtb-10 op-dark-grey" *ngIf="showIncentive">
					<span
						class="op-body-2-font"
						[innerHtml]="
							t(incomeVerified ? 'PLAID_CONNECT.onYourUPLJustByConnectBank' : 'PLAID_CONNECT.afterSuccessfullyConnect')
						"
					>
					</span>
				</div>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="showBenefits">
			<div id="plaidIncentive" class="policy op-mb-8 op-dark-grey" [opTagClick]="'how plaid works'">
				<span class="op-body-2-font" [innerHtml]="t('PLAID_CONNECT.howItWorksContentNoMaxLimit')"></span>
			</div>
			<div class="policy op-mb-10 op-dark-grey" *ngIf="showBenefits">
				<span class="op-body-2-bold-font">{{ t('PLAID_CONNECT.potentialBenefits') }}</span>
			</div>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="!plaidConnectForIncomeVerified">
		<div id="plaidIncentive" class="policy op-mb-12 op-dark-grey" [opTagClick]="'how plaid works'">
			<span class="op-body-2-font" [innerHtml]="t('PLAID_CONNECT.howItWorksContent')"></span>
			<span class="op-body-2-bold-font">{{ t('PLAID_CONNECT.potentialBenefits') }}</span>
		</div>
	</ng-container>
	<ng-container *ngIf="showBenefits">
		<op-plaid-benefits
			[showIncentive]="showIncentive"
			[plaidConnectForIncomeVerified]="plaidConnectForIncomeVerified"
		></op-plaid-benefits>
	</ng-container>
	<div class="op-micro-font op-mt-16 op-mb-20 op-dark-grey" *ngIf="plaidConnectForIncomeVerified">
		<p
			class="policy"
			[innerHTML]="t(showIncentive ? 'PLAID_CONNECT.subjectToAmountLimit' : 'PLAID_CONNECT.neverStoreLoginByClicking')"
		></p>
	</div>

	<div class="never-store op-mtb-8 op-label-font" *ngIf="!plaidConnectForIncomeVerified">
		{{ t('PLAID_CONNECT.neverStoreLoginByClicking') }}
	</div>

	<op-plaid-terms *ngIf="!plaidConnectForIncomeVerified"></op-plaid-terms>

	<div class="op-center">
		<op-plaid-button
			#plaidButton
			class="op-mb-20"
			id="plaidButton"
			minimum="true"
			[sectionType]="sectionType"
			[bankOnly]="bankOnly"
			[plaidConnectForIncomeVerified]="plaidConnectForIncomeVerified"
		></op-plaid-button>
		<div class="op-body-2-font plaid-message op-button-width" *ngIf="bankConnectEvent === 'WAITING'">
			{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.loadingAccounts') }}
		</div>
		<op-alert
			class="plaid-message op-button-width"
			level="ERROR"
			*ngIf="showNoAccountsError && bankConnectEvent !== 'WAITING'"
		>
			{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.noAccountsError') }}
		</op-alert>
	</div>

	<div *ngIf="showSkip" class="op-center op-mb-30">
		<button
			mat-flat-button
			color="secondary"
			class="op-full-width"
			id="skipPlaidLink"
			(click)="skipPlaid()"
			[opTagClick]="'skipPlaid'"
		>
			{{ t(skipButtonKey) }}
		</button>
	</div>

	<op-plaid-terms *ngIf="plaidConnectForIncomeVerified"></op-plaid-terms>
	<op-debit-card-disclaimer class="op-mt-10" *ngIf="plaidConnectForIncomeVerified && newMemberDebitCardEligibility">
	</op-debit-card-disclaimer>
	<op-expansion-panel
		id="plaidWhichBank"
		[titleBold]="false"
		[title]="t('PLAID_CONNECT.whichBank')"
		[opTagClick]="'which bank'"
		[expanded]="false"
	>
		<span class="op-body-2-font">{{ t('PLAID_CONNECT.connectBank') }}</span>
	</op-expansion-panel>

	<ng-template #newMemberDebitCardExperience>
		<op-plaid-benefits
			[plaidConnectForIncomeVerified]="plaidConnectForIncomeVerified"
			[showIncentive]="showIncentive"
		></op-plaid-benefits>
	</ng-template>
</div>
