import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';

@Component({
	selector: 'op-status-co-app-offered',
	templateUrl: './co-app-offered.component.html',
	styleUrls: ['./co-app-offered.component.scss']
})
export class CoAppOfferedComponent implements OnInit {
	private _loanApp: ILoanApplication;
	get loanApp(): ILoanApplication {
		return this._loanApp;
	}

	@Input()
	set loanApp(value: ILoanApplication) {
		this._loanApp = value;
	}

	constructor(private dialogService: DialogService, private tagDataService: TagDataService) {}

	newApplicationStartDate: Date;
	issuingOrganizationIsOportun: boolean;

	ngOnInit(): void {
		this.newApplicationStartDate = new Date(); // TODO fix this
		this.issuingOrganizationIsOportun = OrganizationUtils.isOportun(this.loanApp.issuingOrganization);
	}

	triggerCallDialog(): void {
		const tagEvent = {
			event_category: 'co_app_offered',
			event_action: 'Button Click',
			event_label: 'Call Now'
		};
		this.tagDataService.link({}, tagEvent);
		this.dialogService.openContactUsDialog(false).subscribe();
	}
}
