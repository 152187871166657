<div class="manually-add-bank" *transloco="let t">
	<op-back-link class="op-mt-20" [green]="false" id="addManuallyBack">{{ t('ACCESSIBILITY.back') }}</op-back-link>
	<op-masthead [customClass]="'head-bp-4'" [title]="t(titleKey)"></op-masthead>
	<op-manually-add-bank-ext
		(notSaved)="notSaved()"
		(saved)="bankDetailsSaved($event)"
		[errorPanelEnabled]="errorPanelEnabled"
	></op-manually-add-bank-ext>
	<div class="op-pt-40"></div>
</div>
