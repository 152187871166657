<div class="manually-add-bank" *transloco="let t">
	<form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
		<p
			*ngIf="showAccountNameBannerOnTop"
			id="account-name"
			role="status"
			class="op-gray"
			aria-live="assertive"
			[innerHTML]="t('MANUAL_ADD_BANK.accountName')"
		></p>

		<div id="requiredFields" class="op-label-font op-gray op-mtb-20">
			{{ t('MANUAL_ADD_BANK.requiredFields') }}
		</div>

		<p></p>
		<div class="op-form-group">
			<div class="op-form-title">{{ t('MANUAL_ADD_BANK.accountType') | opRequired }}</div>
			<mat-radio-group name="accountType" id="accountType" formControlName="accountType">
				<mat-radio-button value="CHECKING" id="accountType_checking">
					{{ t('MANUAL_ADD_BANK.checking') }}
				</mat-radio-button>
				<mat-radio-button value="SAVING" id="accountType_saving">
					{{ t('MANUAL_ADD_BANK.savings') }}
				</mat-radio-button>
			</mat-radio-group>
		</div>

		<op-info-tip id="routingInfoTip" [key]="t('MANUAL_ADD_BANK.routingNumberA')">
			{{ t('MANUAL_ADD_BANK.routingNumberQ') }}
		</op-info-tip>

		<mat-form-field class="form-element op-mt-10" appearance="outline">
			<mat-label>{{ t('MANUAL_ADD_BANK.routingNumber') }}</mat-label>
			<input matInput type="text" name="routingNumber" id="routingNumber" formControlName="routingNumber" />
			<mat-hint>{{ bankName }}</mat-hint>
			<mat-error
				id="routingNumberError"
				*ngIf="formGroup.controls['routingNumber'].invalid && formGroup.controls['routingNumber'].touched"
			>
				{{ t('MANUAL_ADD_BANK.errorMsgRouting') }}
			</mat-error>
		</mat-form-field>

		<op-info-tip id="accountInfoTip" [key]="t('MANUAL_ADD_BANK.accountNumberA')">
			{{ t('MANUAL_ADD_BANK.accountNumberQ') }}
		</op-info-tip>

		<mat-form-field class="form-element op-mt-10" appearance="outline">
			<mat-label>{{ t('MANUAL_ADD_BANK.accountNumber') }}</mat-label>
			<input matInput type="text" name="accountNumber" id="accountNumber" formControlName="accountNumber" />
			<mat-error
				id="accountNumberError"
				*ngIf="formGroup.controls['accountNumber'].invalid && formGroup.controls['accountNumber'].touched"
			>
				{{ t('MANUAL_ADD_BANK.errorMsgAccount') }}
			</mat-error>
		</mat-form-field>

		<mat-form-field class="form-element op-mt-10" appearance="outline">
			<mat-label>{{ t('MANUAL_ADD_BANK.confirmAccountNumber') }}</mat-label>
			<input
				matInput
				type="text"
				name="accountNumberConfirmed"
				id="accountNumberConfirmed"
				formControlName="accountNumberConfirmed"
			/>
			<mat-error
				id="accNumberConfirmedError"
				*ngIf="
					formGroup.controls['accountNumberConfirmed'].invalid && formGroup.controls['accountNumberConfirmed'].touched
				"
			>
				{{ t('MANUAL_ADD_BANK.errorMsgAccountMatch') }}
			</mat-error>
		</mat-form-field>

		<mat-checkbox name="customerConfirms" id="customerConfirms" formControlName="customerConfirms" color="primary">
			{{ t(authorizationNote) }}
		</mat-checkbox>
		<a
			*ngIf="!showTermsOnNextLine"
			href=""
			onclick="return false"
			(click)="isTermVisible = !isTermVisible"
			id="termsLink"
			aria-controls="terms-of-loan"
		>
			{{ 'MANUAL_ADD_BANK.termsOfLoanDist' | transloco }}
		</a>
		<div class="op-center op-mt-20" *ngIf="showTermsOnNextLine">
			<a
				href=""
				onclick="return false"
				(click)="isTermVisible = !isTermVisible"
				id="termsLink"
				aria-controls="terms-of-loan"
			>
				{{ 'MANUAL_ADD_BANK.termsOfLoanDist' | transloco }}
			</a>
		</div>
		<p
			*ngIf="isTermVisible && issuingOrganization"
			id="terms-of-loan"
			role="status"
			aria-live="assertive"
			[innerHTML]="t('MANUAL_ADD_BANK.termsOfLoanDistContent.' + issuingOrganization)"
		></p>

		<p></p>
		<button
			mat-flat-button
			color="primary"
			name="submitBtn"
			id="manualAddBankSubmitBtn"
			[disabled]="!formGroup.valid"
			type="submit"
			*ngIf="showContinue"
		>
			{{ t('GLOBAL.continue') }}
		</button>
		<p></p>
		<p
			*ngIf="!showAccountNameBannerOnTop"
			id="account-name"
			role="status"
			aria-live="assertive"
			[innerHTML]="t('MANUAL_ADD_BANK.accountName')"
		></p>
	</form>
</div>
