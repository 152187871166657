<div class="plaid-link-ext" *transloco="let t">
	<div class="add-text" *ngIf="!buttonOnly">
		<div class="benefit" *ngIf="showBenefits">
			<p class="op-body-1-bold-font">{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.benefit') }}</p>
			<op-plaid-benefits [showIncentive]="showIncentive"></op-plaid-benefits>
			<hr class="op-gray" />
		</div>
		<op-plaid-terms class="op-mt-8"></op-plaid-terms>
	</div>

	<div class="op-center plaid-button op-mt-20">
		<button
			mat-flat-button
			[color]="color"
			id="{{ id + '_plaidOpenBtn' }}"
			[disabled]="isBankConnectAvailable"
			(click)="openPlaid()"
		>
			<span>{{ t(plaidButtonText) }}</span>
			<mat-icon svgIcon="op-popout" class="op-ml-10"></mat-icon>
		</button>
	</div>
	<op-website-terms class="op-mt-12 op-mb-20" *ngIf="!buttonOnly"></op-website-terms>
</div>
