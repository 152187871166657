export interface ILoanAppProductOffersEvent {
	applicantId?: number;
	productCategory?: string;
	eventType?: string;
	eventCategory?: string;
	clientId?: number;
	source?: string;
	selected?: boolean;
	details?: string;
	loanApplicationId?: number;
}

export enum LoanAppProductOfferEventTypeEnum {
	continueEventType = 'CONTINUE_FROM_PRE_APPROVED',
	browseEventType = 'PRE_APPROVED_BROWSE_TERMS',
	compareEventType = 'COMPARE_TERMS'
}
