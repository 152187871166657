import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'op-bank-icon',
	templateUrl: './op-bank-icon.component.html',
	styleUrls: ['./op-bank-icon.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BankIconComponent {
	BANK_NAME_ICON_MAPPINGS: Map<string, string> = new Map<string, string>([
		['BANK OF AMERICA', 'op-bank-of-america'],
		['WELLS FARGO', 'op-wells-fargo'],
		['CHASE', 'op-chase'],
		['BANK OF AMERICA, N.A.', 'op-bank-of-america'],
		['JPMORGAN CHASE', 'op-chase'],
		['WELLS FARGO BANK NA', 'op-wells-fargo'],
		['JPMORGAN CHASE BANK, NA', 'op-chase'],
		['WELLS FARGO BANK', 'op-wells-fargo'],
		['CHIME', 'op-chime'],
		['CAPITAL ONE', 'op-capital-one']
	]);

	prefix = 'banks';

	bankIcon: string = 'op-bank';

	@Input()
	set bankName(value: string) {
		const formatedBankName = value.replace(/  +/g, ' ').toUpperCase();
		if (this.BANK_NAME_ICON_MAPPINGS.has(formatedBankName)) {
			this.bankIcon = `${this.prefix}/${this.BANK_NAME_ICON_MAPPINGS.get(formatedBankName)}`;
		}
	}
}
