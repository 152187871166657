<div class="disbursement-options" *transloco="let t">
	<op-masthead [title]="t('DISBURSEMENT_SELECTION.title')"></op-masthead>
	<div *ngIf="showDebitCardError">
		<div class="op-header-1-font">{{ t('DISBURSEMENT_SELECTION.DEBIT_CARD.NOT_SUPPORTED.title') }}</div>
		<div class="op-mtb-20 op-dark-grey">{{ t('DISBURSEMENT_SELECTION.DEBIT_CARD.NOT_SUPPORTED.message') }}</div>
		<div class="op-mb-20 op-dark-grey">{{ t('DISBURSEMENT_SELECTION.DEBIT_CARD.NOT_SUPPORTED.note') }}</div>
	</div>
	<op-content-container class="content" *ngIf="showFundingOptions">
		<div *ngIf="previousSelection?.disbursementType" class="op-body-2-font op-black-bold op-mb-16">
			{{ t('DISBURSEMENT_SELECTION.previouslySelected') }}
		</div>
		<op-funding-selection-card
			*ngIf="previousSelection?.disbursementType"
			[paymentMethodTitle]="previousSelection.paymentMethodTitle"
			[paymentMethodDuration]="previousSelection.paymentMethodDuration"
			[paymentMethodNote]="previousSelection.paymentMethodNote"
			[paymentMethodIcon]="previousSelection.paymentMethodIcon"
			[debitCardLocked]="eligibleForDebitCardLock"
			[hideHeader]="previousSelection.hideHeader"
			[debitCardDetails]="previousSelection?.disbursementType === DisbursementType.debit ? debitCardDetails : null"
			[groupedAccountList]="previousSelection?.disbursementType === DisbursementType.ach ? selectedAccountMap : null"
			[id]="previousSelection.id"
			(click)="continueWithPreviousSelection()"
		>
		</op-funding-selection-card>
		<div *ngIf="previousSelection?.disbursementType" class="op-body-2-font op-black-bold op-mb-16">
			{{ t('DISBURSEMENT_SELECTION.otherOptions') }}
		</div>
		<op-funding-selection-card
			opTagView
			[opViewEvent]="'debit card displayed'"
			[opViewLabel]="loanAppService.isGCPOrReturnApplicant() ? 'returning' : 'new'"
			*ngIf="
				(eligibleForDebitCard || eligibleForDebitCardLock) &&
				!showDebitCardError &&
				previousSelection?.disbursementType !== DisbursementType.debit
			"
			[id]="'debit_card'"
			[paymentMethodTitle]="t('DISBURSEMENT_SELECTION.DEBIT_CARD.title')"
			[paymentMethodDuration]="t('DISBURSEMENT_SELECTION.DEBIT_CARD.fundWithin')"
			[paymentMethodNote]="t('DISBURSEMENT_SELECTION.DEBIT_CARD.UNLOCK.connectBankAccountToUseDebitCard')"
			paymentMethodIcon="op-atmCard"
			[debitCardDetails]="debitCardDetails"
			(click)="onDebitCardSelection()"
			[debitCardLocked]="eligibleForDebitCardLock"
		>
		</op-funding-selection-card>
		<op-funding-selection-card
			[id]="'ach'"
			[paymentMethodTitle]="t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.title')"
			[paymentMethodDuration]="t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.fundWithin')"
			[groupedAccountList]="
				previousSelection?.disbursementType === DisbursementType.ach ? unselectedAccountMap : accountMap
			"
			[hasBankAccount]="hasBankAccount"
			paymentMethodIcon="op-bank"
			(bankAccountSelected)="onBankSelection($event)"
		>
		</op-funding-selection-card>

		<op-funding-selection-card
			*ngIf="previousSelection?.disbursementType !== DisbursementType.check"
			[id]="'check'"
			[paymentMethodTitle]="t('DISBURSEMENT_SELECTION.CHECK.title')"
			[paymentMethodDuration]="t('DISBURSEMENT_SELECTION.CHECK.fundWithin')"
			[paymentMethodNote]="t('DISBURSEMENT_SELECTION.CHECK.info')"
			paymentMethodIcon="op-paperCheck"
			(click)="onCheckSelection()"
		>
		</op-funding-selection-card>

		<div
			class="op-gray op-label-font"
			*ngIf="(eligibleForDebitCard || eligibleForDebitCardLock) && !showDebitCardError"
		>
			{{ t('DISBURSEMENT_SELECTION.expectDelayNotice') }}
		</div>
	</op-content-container>
</div>
