<div class="moneylion" *transloco="let t">
	<mat-card id="moneylion" class="card">
		<mat-card-title class="title">
			<div class="op-body-1-bold-font">
				{{ t('STATUS.NOT_APPROVED.MONEY_LION.goodNews') }}
			</div>
		</mat-card-title>
		<mat-card-content class="content">
			<img alt="" class="moneylion-logo op-mb-12" src="/assets/images/moneyLion/MoneyLionLogo.svg" />
			<div class="op-body-1-bold-font op-mt-12 op-mb-20 op-center">
				{{ t('STATUS.NOT_APPROVED.MONEY_LION.loansFrom') }}
			</div>
			<div class="op-body-1-font op-primary-black">
				<div *ngFor="let description of t('STATUS.NOT_APPROVED.MONEY_LION.moneyLionDescription')">
					<div class="moneyLion-description">
						<mat-icon svgIcon="op-checkMark-rounded"></mat-icon>
						<div>{{ description }}</div>
					</div>
				</div>
			</div>
			<div class="">
				<button
					color="primary"
					class="op-full-width moneyLion-button"
					mat-flat-button
					id="moneyLionBtn"
					(click)="goToMoneyLion()"
				>
					{{ t('STATUS.NOT_APPROVED.MONEY_LION.moneyLionOptions') }}
					<mat-icon svgIcon="op-popout"> </mat-icon>
				</button>
				<div class="op-body-2-font op-light-black op-mt-8 op-mb-16">
					{{ t('STATUS.NOT_APPROVED.MONEY_LION.moneyLionRedirect') }}
				</div>
				<hr />
				<div class="op-micro-font op-light-black op-mt-16 op-mb-16">
					{{ t('STATUS.NOT_APPROVED.MONEY_LION.notAffiliated') }}
				</div>
			</div>
		</mat-card-content>
	</mat-card>
</div>
