import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { AuthenticationGuard } from './guards/authentication.guard';
import { DisbursementRoutingGuard } from './guards/disbursement-routing.guard';
import { EligibilityRoutingGuard } from './guards/eligibility-routing.guard';
import { FundingOptionsRoutingGuard } from './guards/funding-options-routing.guard';
import { interceptorProviders } from './interceptors/interceptors';
import { AchBankAccountsService } from './services/ach-bank-accounts/ach-bank-accounts.service';
import { AutoPayBankAccountVerificationService } from './services/auto-pay-bank-account-verification/auto-pay-bank-account-verification.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { CastleService } from './services/castle/castle.service';
import { DateUtilsService } from './services/date-utils/date-utils.service';
import { DeviceDetectorService } from './services/device-detector/device-detector.service';
import { DialogService } from './services/dialog/dialog.service';
import { EnvironmentService } from './services/environment/environment.service';
import { FileUploadService } from './services/file-upload/file-upload.service';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';
import { GoogleApiPlacesService } from './services/google-api-places/google-api-places.service';
import { IdleTimeoutService } from './services/idle-timeout/idle-timeout.service';
import { InstrumentationService } from './services/instrumentation/instrumentation.service';
import { LandingService } from './services/landing/landing.service';
import { LoanApplicationService } from './services/loan-application/loan-application.service';
import { LoanExamplesService } from './services/loan-examples/loan-examples.service';
import { LoggingService } from './services/logging/logging.service';
import { MetadataService } from './services/metadata/metadata.service';
import { MobileApiBusyNotifierService } from './services/mobile-api-busy-notifier/mobile-api-busy-notifier.service';
import { MobileApiService } from './services/mobile-api/mobile-api.service';
import { NeuroIdService } from './services/neuro-id/neuro-id.service';
import { NewRelicService } from './services/new-relic/new-relic.service';
import { PlaidLinkService } from './services/plaid-link/plaid-link.service';
import { LandingQueryTrackingGuard } from './guards/landing-query-tracking.guard';
import { ScriptLoaderService } from './services/script-loader/script-loader.service';
import SingleSignOnService from './services/single-sign-on/single-sign-on.service';
import { SoftPullService } from './services/soft-pull/soft-pull.service';
import { LocalStorageService } from './services/storage/local-storage.service';
import { SessionStorageService } from './services/storage/session-storage.service';
import TokenService from './services/token/token.service';
import { WindowRefService } from './services/window-ref/window-ref.service';

/**
 * Contains the global services, interceptors, and guards. (singletons)
 *
 * @export
 * @class CoreModule
 */
@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [
		...interceptorProviders,
		AchBankAccountsService,
		AutoPayBankAccountVerificationService,
		AuthenticationGuard,
		AuthenticationService,
		CastleService,
		DateUtilsService,
		DeviceDetectorService,
		DisbursementRoutingGuard,
		DialogService,
		EnvironmentService,
		EligibilityRoutingGuard,
		FundingOptionsRoutingGuard,
		FileUploadService,
		GoogleAnalyticsService,
		GoogleApiPlacesService,
		IdleTimeoutService,
		InstrumentationService,
		LandingService,
		LoanApplicationService,
		LoanExamplesService,
		LocalStorageService,
		LoggingService,
		MetadataService,
		MobileApiBusyNotifierService,
		MobileApiService,
		NeuroIdService,
		NewRelicService,
		PlaidLinkService,
		LandingQueryTrackingGuard,
		ScriptLoaderService,
		SessionStorageService,
		SingleSignOnService,
		SoftPullService,
		TokenService,
		WindowRefService
	]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import it in the AppModule only');
		}
	}
}
