import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../../environment/environment.service';
import { ApiUrlService } from '../api-url.service';
import { IResult, IStringResult } from '../mobile-api.model';
import { SKIP_BUSY_INDICATOR } from 'src/app/core/interceptors/mobile-api-busy.interceptor';
import { ILoanAppProductOffersEvent } from './product-offers-event-log-model';

@Injectable({
	providedIn: 'root'
})
export class ProductOffersEventLogApiService extends ApiUrlService {
	constructor(protected environmentService: EnvironmentService, private http: HttpClient) {
		super(environmentService);
	}

	logLoanAppEvent(eventLog: ILoanAppProductOffersEvent): Observable<IStringResult> {
		const context = new HttpContext().set(SKIP_BUSY_INDICATOR, true);
		return this.http.post<IStringResult>(
			`${this.API_MOBILE_LOAN_APP_URL}/${eventLog.loanApplicationId}/product-offer/event`,
			eventLog,
			{ context }
		);
	}
}
