import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ILoanApplication } from '../loan-application/loan-application.model';
import {
	IFastTrackData,
	IFindApplication,
	IFindApplicationResult,
	IFindLetter,
	ILeadsByNameAddress,
	ILeadsByNameAddressResult,
	IMultiFactor,
	IMultiFactorResult,
	INewApplication,
	INewApplicationResult,
	IOfferCodeResult,
	IVerifyFactor,
	IVerifyFactorResult,
	IZipCodeResult
} from '../mobile-api/mobile-api.model';
import { MobileApiService } from '../mobile-api/mobile-api.service';
import { SessionStorageService } from '../storage/session-storage.service';
import { IFindELettersResult, IFindELetters } from '../mobile-api/mobile-api.model';

export interface ILeadByNameAddressInformation extends ILeadsByNameAddressResult {
	partnerSource: string;
}

export interface IOfferCodeInformation extends IOfferCodeResult {
	finderNumber?: string;
}
export interface ILandingData {
	zipCode: string;
	findApplication: IFindApplicationResult;
	newApplication: INewApplicationResult;
	specialOfferLead: ILeadsByNameAddress;
	specialOfferLeadInformation: ILeadByNameAddressInformation;
	offerCodeInformation: IOfferCodeInformation;
	multiFactor: IVerifyFactorResult;
	token: string;
	fastTrack?: IFastTrackData;
	findELetters?: IFindELettersResult;
}

/**
 * Service to track information about the user before they are authenticated.
 * When finding an application authentication happens after the multifactor.
 * When creating a new application authentication happens after the app is created.
 *
 * @export
 * @class PreAuthorizationService
 */
@Injectable({
	providedIn: 'root'
})
export class LandingService implements OnDestroy {
	private readonly landingSource = new BehaviorSubject<ILandingData>(null);
	private readonly zipCodeLookupSource = new BehaviorSubject<IZipCodeResult>(null);

	// Exposed observable (read-only).
	readonly landing$: Observable<ILandingData> = this.landingSource.asObservable();
	readonly zipCodeLookup$: Observable<IZipCodeResult> = this.zipCodeLookupSource.asObservable();
	private readonly storageKey = 'landing';
	private readonly subscription = new Subscription();

	constructor(private mobileService: MobileApiService, private sessionStorageService: SessionStorageService) {
		const storageKeySub = this.sessionStorageService.select(this.storageKey).subscribe({
			next: (value) => {
				this.landingSource.next(value);
			}
		});
		this.subscription.add(storageKeySub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private setLandingSource(data: ILandingData) {
		this.sessionStorageService.set(this.storageKey, data);
	}

	/**
	 * Search to see if a zip code is available for loan service.
	 *
	 * @param {string} zipCode
	 * @param {string} [promoCode=null]
	 * @param {string} [lastName=null]
	 * @return {*}  {Observable<IZipCodeResult>}
	 * @memberof LandingService
	 */
	searchZipCode(
		zipCode: string,
		promoCode: string = null,
		lastName: string = null
	): Observable<IZipCodeResult | IOfferCodeResult> {
		this.clearPreAuthorization();
		return this.mobileService.checkZipCode(zipCode, promoCode, lastName).pipe(
			tap(() => this.updateZipCode(zipCode)),
			tap((rsp) => this.updatePromoCodeInfo(rsp, promoCode))
		);
	}

	/**
	 * get the zip code of the current user before a loan application is created.
	 *
	 * @returns {string}
	 * @memberof PreAuthorizationService
	 */
	getZipCode(): string {
		return this.landingSource.getValue()?.zipCode || '';
	}

	getPhoneNumber(): string {
		const obj = this.landingSource.getValue();
		return (
			obj?.findApplication?.phoneNumber ||
			obj?.newApplication?.phoneNumber ||
			obj?.fastTrack?.phoneNumber ||
			obj?.findELetters?.data?.phoneNumber
		);
	}

	getPhoneType(): string {
		const obj = this.landingSource.getValue();
		return (
			obj?.findApplication?.phoneType ||
			obj?.newApplication?.phoneType ||
			obj?.fastTrack?.phoneType ||
			obj?.findELetters?.data.phoneType
		);
	}

	getPromoCodeInformation(): IOfferCodeInformation {
		return this.landingSource.getValue()?.offerCodeInformation || null;
	}

	private updatePromoCodeInfo(offerCodeResult: IZipCodeResult | IOfferCodeResult, promoCode: string): void {
		if ('last_name' in offerCodeResult) {
			const offerCodeInformation = { ...offerCodeResult, finderNumber: promoCode };
			const offerInfo = { ...this.landingSource.getValue(), offerCodeInformation };
			this.setLandingSource(offerInfo);
		}
		if ('issuingOrganization' in offerCodeResult) {
			this.zipCodeLookupSource.next(offerCodeResult);
		}
	}

	private updateZipCode(zipCode: string): void {
		const zip = { ...this.landingSource.getValue(), zipCode };
		this.setLandingSource(zip);
	}

	/**
	 * Search to see if a loan application is currently available to continue.
	 *
	 * @param {IFindApplication} search
	 * @returns {Observable<IFindApplicationResult>}
	 * @memberof PreAuthorizationService
	 */
	searchApplication(search: IFindApplication): Observable<IFindApplicationResult> {
		return this.mobileService.findApplication(search).pipe(tap(this.updateFindApp.bind(this)));
	}

	/**
	 * Search to see if a AA/NIOA letter is available to continue.
	 *
	 * @param {IFindELetters} search
	 * @returns {Observable<IFindELettersResult>}
	 * @memberof PreAuthorizationService
	 */
	searchLetter(search: IFindELetters): Observable<IFindELettersResult> {
		return this.mobileService.findLetter(search);
	}

	/**
	 * Get the application information that is available before the user is authenticated.
	 *
	 * @returns {IFindApplicationResult}
	 * @memberof PreAuthorizationService
	 */
	getFindApplicationInformation(): IFindApplicationResult {
		return this.landingSource.getValue()?.findApplication || null;
	}

	updateFindApp(findApplication: IFindApplicationResult): void {
		const find = { ...this.landingSource.getValue(), findApplication, token: findApplication.token };
		this.setLandingSource(find);
	}

	updateFindELetters(findELetters: IFindELettersResult): void {
		const find = { ...this.landingSource.getValue(), findELetters, token: findELetters.data.token };
		this.setLandingSource(find);
	}

	/**
	 * Submit information to create a new application
	 *
	 * @param {INewApplication} newApp
	 * @returns {Observable<INewApplicationResult>}
	 * @memberof PreAuthorizationService
	 */
	submitNewApplication(newApp: INewApplication, refId: string): Observable<INewApplicationResult> {
		return this.mobileService.newApplication(newApp, refId).pipe(tap(this.updateNewApplication.bind(this)));
	}

	submitNewSecuredApplication(newApp: INewApplication, refId: string): Observable<INewApplicationResult> {
		return this.mobileService.newSecuredApplication(newApp, refId).pipe(tap(this.updateNewApplication.bind(this)));
	}

	private buildNewAppResult(loanApp: ILoanApplication, app: INewApplication): INewApplicationResult {
		const applicant = loanApp?.applicants?.find(Boolean);
		return {
			loanApplicationId: applicant.loanApplicationId,
			applicantId: applicant.id,
			firstName: app.firstName,
			phoneNumber: app.phoneNumber,
			phoneType: app.phoneType,
			preferredLanguage: app.preferredLanguage,
			hasRecentNotApprovedApp: null,
			newApplicationStartDate: null,
			updatedAt: null,
			hasActiveLoan: null,
			inProgressApplication: null,
			returningCustomer: null,
			token: null,
			timeoutInMinutes: null
		};
	}

	updateApplicant(app: INewApplication, loanAppId: number): Observable<ILoanApplication> {
		return this.mobileService.updateApplicant(app, loanAppId).pipe(
			tap((loanApp) => {
				let { firstName, phoneType, preferredLanguage, phoneNumber } = app;
				phoneNumber = phoneNumber.slice(phoneNumber.length - 4);
				const savedApp = this.landingSource.getValue()?.newApplication || this.buildNewAppResult(loanApp, app);
				const newApp = {
					...savedApp,
					firstName,
					phoneType,
					preferredLanguage,
					phoneNumber
				};
				this.updateNewApplication(newApp);
			})
		);
	}

	private updateNewApplication(newApplication: INewApplicationResult): void {
		const app = { ...this.landingSource.getValue(), newApplication, token: newApplication.token };
		this.setLandingSource(app);
	}

	getNewApplicationResult(): INewApplicationResult {
		return this.landingSource.getValue()?.newApplication ?? null;
	}

	/**
	 * Verify the users multi factor information.
	 *
	 * @param {IVerifyFactor} data
	 * @returns {Observable<IVerifyFactorResult>}
	 * @memberof PreAuthorizationService
	 */
	verifyMultiFactor(data: IVerifyFactor, refId?: string): Observable<IVerifyFactorResult> {
		return this.mobileService.verifyFactor(data, refId).pipe(tap(this.updateMultiFactor.bind(this)));
	}

	private updateMultiFactor(multiFactor: IVerifyFactorResult): void {
		const app = { ...this.landingSource.getValue(), multiFactor };
		this.setLandingSource(app);

		//Required for GCP flow and FYA flow
		this.clearPreAuthorization();
	}

	/**
	 * Submit the multi factor code to verify the user.
	 *
	 * @param {IMultiFactor} data
	 * @returns {Observable<IMultiFactorResult>}
	 * @memberof PreAuthorizationService
	 */
	submitMultiFactorCode(data: IMultiFactor): Observable<IMultiFactorResult> {
		return this.mobileService.multiFactor(data);
	}

	/**
	 * Clear pre auth data.
	 *
	 * @memberof PreAuthorizationService
	 */
	clearPreAuthorization(): void {
		this.sessionStorageService.remove(this.storageKey);
	}

	getSpecialOfferInformation(): ILeadByNameAddressInformation {
		return this.landingSource.getValue()?.specialOfferLeadInformation ?? null;
	}

	getSpecialOfferLead(): ILeadsByNameAddress {
		return this.landingSource.getValue()?.specialOfferLead ?? null;
	}

	private updateSpecialOfferInformation(
		specialOfferLeadInformation: ILeadByNameAddressInformation
	): ILeadByNameAddressInformation {
		const find = { ...this.landingSource.getValue(), specialOfferLeadInformation };
		this.sessionStorageService.set(this.storageKey, find);
		return specialOfferLeadInformation;
	}

	private updateSpecialOffer(specialOfferLead: ILeadsByNameAddress): ILeadsByNameAddress {
		const find = { ...this.landingSource.getValue(), specialOfferLead };
		this.sessionStorageService.set(this.storageKey, find);
		return specialOfferLead;
	}

	getLeadsByNameAndAddress(
		leadsData: ILeadsByNameAddress,
		partnerSource: string
	): Observable<ILeadByNameAddressInformation> {
		this.updateZipCode(leadsData.zipCode);
		this.updateSpecialOffer(leadsData);
		return this.mobileService
			.getLeadsByNameAndAddress(leadsData)
			.pipe(map((rsp) => this.updateSpecialOfferInformation({ ...rsp, partnerSource })));
	}

	updatePrescreenOfferDetails(
		specialOfferLead: ILeadsByNameAddress,
		specialOfferLeadInformation: ILeadByNameAddressInformation
	): void {
		if (Boolean(specialOfferLead)) {
			this.updateZipCode(specialOfferLead.zipCode);
			this.updateSpecialOffer(specialOfferLead);
		}
		if (Boolean(specialOfferLeadInformation)) {
			this.updateSpecialOfferInformation(specialOfferLeadInformation);
		}
	}

	updateFastTrackData(fastTrackData: IFastTrackData): void {
		const landingData = { ...this.landingSource.getValue(), fastTrack: fastTrackData };
		this.setLandingSource(landingData);
	}
}
