import { Directive, ElementRef, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

@Directive({
	selector: '[opTagView]'
})
export class OpTagViewDirective implements AfterViewInit, OnDestroy {
	@Input() opViewLabel: string;

	@Input() opViewEvent: string;

	@Input() opViewCheck: boolean = false;

	private observer: IntersectionObserver | undefined;

	constructor(private elementRef: ElementRef, private tagDataService: TagDataService) {}

	ngAfterViewInit(): void {
		this.observer = new IntersectionObserver((entries) => {
			if (!this.opViewCheck) {
				this.captureEvent();
			} else {
				const inView: boolean = entries.some((entry) => entry.isIntersecting === true);
				if (inView) {
					this.captureEvent();
				}
			}
		});
		if (this.elementRef?.nativeElement) {
			this.observer.observe(this.elementRef?.nativeElement);
		}
	}

	ngOnDestroy(): void {
		this.observer?.disconnect();
	}

	private captureEvent(): void {
		this.tagDataService.link(
			{},
			{
				tealium_event: this.opViewEvent,
				event_label: this.opViewLabel,
				event_action: 'on_page_load'
			}
		);
	}
}
