<div class="digitOffer" *transloco="let t">
	<mat-card id="digitOffer" class="card">
		<mat-card-title class="title">
			<div class="op-display-3-font">
				<span class="underline">{{ t('STATUS.NOT_APPROVED.DIGIT_OFFER.12Months') }}</span
				>{{ t('STATUS.NOT_APPROVED.DIGIT_OFFER.effortlessSavings') }}
			</div>
			<div class="op-body-1-font op-mt-12 op-light-black">
				{{ t('STATUS.NOT_APPROVED.DIGIT_OFFER.trySmartSavings') }}
			</div>
		</mat-card-title>
		<mat-card-content class="content">
			<button mat-flat-button id="offerButton" color="primary-small" (click)="goToDigit()">
				{{ t('STATUS.NOT_APPROVED.DIGIT_OFFER.getNow') }}
			</button>
			<div class="automatic-saving">
				<div>
					<div class="op-micro-font op-light-black op-mt-8">
						{{ t('STATUS.NOT_APPROVED.DIGIT_OFFER.costPerMonth') }}
					</div>
				</div>
				<div class="automatic-saving-image">
					<img class="phone-image" [src]="image" alt="app offer" role="presentation" />
				</div>
			</div>
		</mat-card-content>
	</mat-card>
	<div class="disclaimer op-micro-font op-mt-8">
		<div id="esDisclaimer" class="op-mt-10 op-left op-light-black">
			{{ 'DIGIT_OFFER_FOR_NOT_APPROVED.disclaimer' | transloco }}
		</div>
	</div>
</div>
