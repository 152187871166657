import { Component, Input } from '@angular/core';
import { PlaidButtonComponent } from '../plaid-button/plaid-button.component';
/**
 * Display button to open plaid
 *
 * @export
 * @class PlaidComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
	selector: 'op-plaid-button-ext',
	templateUrl: './plaid-button-ext.component.html',
	styleUrls: ['./plaid-button-ext.component.scss']
})
export class PlaidButtonExtComponent extends PlaidButtonComponent {
	@Input()
	showBenefits: boolean;

	@Input()
	buttonOnly: boolean = false;

	@Input()
	showIncentive: boolean = false;

	@Input()
	plaidButtonText: string = 'DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.connectNew';
}
