import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { ISetAchBankAccountDetail } from 'src/app/core/services/mobile-api';

import { DisbursementType } from 'src/app/core/services/loan-application/disbursement/disbursement.model';
import { FundingMethodConfirmComponent } from 'src/app/shared/components/dialogs/funding-method-confirm/funding-method-confirm.component';

@Component({
	selector: 'op-add-manually',
	templateUrl: './add-manually.component.html',
	styleUrls: ['./add-manually.component.scss']
})
export class AddManuallyComponent {
	protected titleKey: string = 'DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.title';

	protected nextRoute = RoutingPathsEnum.documentSubmit;

	protected errorPanelEnabled: boolean = false;

	constructor(
		protected dialogService: DialogService,
		protected routingService: RoutingService,
		protected translocoService: TranslocoService
	) {}

	notSaved() {
		this.dialogService
			.openErrorDialogWithTitle(
				this.translocoService.translate('FIND_YOUR_APPLICATION.ERROR_MODAL.title'),
				this.translocoService.translate('FIND_YOUR_APPLICATION.ERROR_MODAL.message')
			)
			.subscribe();
	}

	bankDetailsSaved($event: ISetAchBankAccountDetail): void {
		this.dialogService
			.openSimpleDialog(FundingMethodConfirmComponent, {
				data: {
					fundingOptions: DisbursementType.ach,
					account: {
						bankName: $event.bankName,
						accountType: $event.accountType,
						accountNumber: $event.accountNumber
					}
				}
			})
			.subscribe({
				next: (result) => {
					this.routingService.route(this.nextRoute);
				},
				error: () => {}
			});
	}
}
