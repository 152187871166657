<div class="op-plaid-benefits" *transloco="let t">
	<ng-container [ngSwitch]="forPage">
		<ng-container *ngSwitchCase="'add-bank-account'" [ngTemplateOutlet]="add_bank_account"></ng-container>
		<ng-container *ngSwitchCase="'esign-auto-pay/add-bank'" [ngTemplateOutlet]="add_bank_account"></ng-container>
		<ng-container
			*ngSwitchCase="'funding-selection/add-bank-account'"
			[ngTemplateOutlet]="funding_selection_add_bank_account"
		></ng-container>
		<ng-container *ngSwitchCase="'income-verification'" [ngTemplateOutlet]="income_verification"></ng-container>
		<ng-container *ngSwitchCase="'bank-verification'" [ngTemplateOutlet]="income_verification"></ng-container>
		<ng-container *ngSwitchCase="'plaid-connect'" [ngTemplateOutlet]="plaid_connect"></ng-container>
	</ng-container>

	<ng-template #income_verification class="op-green">
		<ng-container *ngIf="!plaidConnectForIncomeVerified">
			<ng-container [ngTemplateOutlet]="plaid_connect"></ng-container>
		</ng-container>
		<ng-container *ngIf="plaidConnectForIncomeVerified">
			<div class="op-icon-align-body-2-green" *ngIf="showIncentive">
				<mat-icon aria-hidden="true" svgIcon="op-moneyBag"></mat-icon>
				<span class="op-body-1-bold-font op-full-width" [innerHTML]="t('PLAID_CONNECT.getLoanAmountIncreased')"> </span>
				<p></p>
				<p class="op-body-2-font op-full-width op-dark-grey">
					{{ t('PLAID_CONNECT.subjectToAmountAndStateLimit') }}
				</p>
			</div>
			<div class="op-icon-align-body-2-green">
				<mat-icon aria-hidden="true" svgIcon="op-lightning"></mat-icon>
				<span class="op-body-1-bold-font">
					{{ t('PLAID_CONNECT.getFundsWithin30Mins') }}
				</span>
				<p></p>
				<p class="op-body-2-font op-full-width op-dark-grey">
					{{ t('PLAID_CONNECT.viaDebitCardAfterLoanClosing') }}
				</p>
			</div>
		</ng-container>
	</ng-template>

	<ng-template #plaid_connect>
		<div class="op-icon-align-body-1 op-mb-10">
			<mat-icon class="op-dark-green" svgIcon="op-history"></mat-icon>
			<span class="op-primary-black" [innerHtml]="t('PLAID_CONNECT.fasterLoanApplication')"></span>
		</div>
		<div class="op-icon-align-body-1 op-mb-10">
			<mat-icon class="op-dark-green" svgIcon="op-refreshDouble"></mat-icon>
			<span class="op-primary-black" [innerHtml]="t('PLAID_CONNECT.automatedVerification')"></span>
		</div>
		<div class="op-icon-align-body-1" *ngIf="showIncentive">
			<mat-icon class="op-dark-green" svgIcon="op-graphAscend"> </mat-icon>
			<span class="op-primary-black" [innerHtml]="t('PLAID_CONNECT.expeditedFunds')"></span>
		</div>
	</ng-template>

	<ng-template #add_bank_account>
		<div class="benefit-item op-mtb-16">
			<div class="benefit-title op-mb-4 op-icon-align-body-1">
				<mat-icon svgIcon="op-history" class="op-dark-green"></mat-icon>
				<span>{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.expeditedFunds.title') }}</span>
			</div>
			<div class="benefit-description op-gray">
				{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.expeditedFunds.description') }}
			</div>
		</div>
		<div class="benefit-item op-mtb-16">
			<div class="benefit-title op-mb-4 op-icon-align-body-1">
				<mat-icon svgIcon="op-refreshDouble" class="op-dark-green"></mat-icon>
				<span [innerHTML]="t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.fewerDoc.title')"></span>
			</div>
			<div class="benefit-description op-gray">
				{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.fewerDoc.description') }}
			</div>
		</div>
		<div class="benefit-item op-mt-16" *ngIf="showIncentive">
			<div class="benefit-title op-mb-4 op-icon-align-body-1">
				<mat-icon svgIcon="op-ascend" class="op-dark-green"></mat-icon>
				<span>{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.higherAmount.title') }}</span>
			</div>
			<div class="benefit-description op-gray">
				{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.higherAmount.description') }}
			</div>
		</div>
		<div class="benefit-item op-mt-4 op-mb-12 op-label-font op-gray" *ngIf="showIncentive">
			{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.BENEFITS.subjectToLimits') }}
		</div>
	</ng-template>
	<ng-template #funding_selection_add_bank_account>
		<div class="funding-options-add-bank-account">
			<ol class="benefit-item flex-column op-mtb-16">
				<li class="op-mb-20">
					<div class="benefit-description flex-center flex-column op-ml-10">
						<span class="op-body-2-bold-font">{{
							t('DISBURSEMENT_SELECTION.DEBIT_CARD.UNLOCK.connectBankAccountForVerification')
						}}</span>
					</div>
				</li>
				<li>
					<div class="benefit-description flex-center flex-column op-ml-10">
						<span class="op-body-2-bold-font">{{
							t('DISBURSEMENT_SELECTION.DEBIT_CARD.UNLOCK.enterYourDebitCardDetails')
						}}</span>
						<span class="op-gray op-label-font">{{
							t('DISBURSEMENT_SELECTION.DEBIT_CARD.UNLOCK.canBeFromAnotherBank')
						}}</span>
					</div>
				</li>
			</ol>
		</div>
	</ng-template>
</div>
