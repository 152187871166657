<div class="add-bank-account" *transloco="let t">
	<op-back-link class="op-mt-20" [green]="false" id="addBankAccountBack">{{ t('ACCESSIBILITY.back') }}</op-back-link>
	<div *ngIf="accountList.length === 0 || showAddButton">
		<op-masthead [customClass]="'head'" [title]="t(titleKey)"></op-masthead>
		<op-content-container class="content">
			<op-plaid-benefits></op-plaid-benefits>
			<op-error-panel
				*ngIf="hasBankAccountError"
				[errorMessage]="'DISBURSEMENT_SELECTION.DEBIT_CARD.UNLOCK.bankAccountNotAdded'"
			>
			</op-error-panel>
			<op-plaid-button-ext
				id="plaidButton"
				[bankOnly]="true"
				[buttonOnly]="true"
				[showIncentive]="false"
				[plaidButtonText]="'DISBURSEMENT_SELECTION.DEBIT_CARD.UNLOCK.connectBank'"
			></op-plaid-button-ext>
			<div class="op-gray op-body-2-font op-center op-mt-4">{{ t('PLAID_CONNECT.getLoanAmountIncreasedStar') }}</div>
			<op-horizontal-rule class="op-label-font" [text]="t('GLOBAL.or')" [textOnly]="true"></op-horizontal-rule>

			<op-funding-method-accordion
				[id]="'add-bank-manually'"
				[paymentMethodTitle]="t('DISBURSEMENT_SELECTION.DEBIT_CARD.UNLOCK.addBankAccountManually')"
				[paymentMethodInfo]="t('DISBURSEMENT_SELECTION.DEBIT_CARD.UNLOCK.withAccountDetails')"
				[compactView]="true"
				paymentMethodIcon="op-bank"
			>
				<op-manually-add-bank-ext
					id="manuallyAddBank"
					[showContinue]="true"
					[showTermsOnNextLine]="false"
					[showAccountNameBannerOnTop]="false"
					[errorPanelEnabled]="true"
					[authorizationNote]="'DISBURSEMENT_SELECTION.DEBIT_CARD.UNLOCK.fundDistributionNote'"
					(saved)="bankDetailsSaved($event)"
				>
				</op-manually-add-bank-ext>
			</op-funding-method-accordion>
		</op-content-container>
	</div>
	<div *ngIf="accountList.length > 0 && !showAddButton">
		<op-masthead
			[customClass]="'head-bp-4'"
			[title]="t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.CONNECTED.title')"
		></op-masthead>
		<div class="op-gray op-body-1-font">{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.message') }}</div>
		<op-add-bank-ext
			(addAnotherBankAccount)="hideAccountList($event)"
			(bankSelected)="onBankSelected($event)"
		></op-add-bank-ext>
	</div>
	<op-plaid-terms class="op-mt-10"></op-plaid-terms>
	<op-debit-card-disclaimer class="op-mt-10"></op-debit-card-disclaimer>
</div>
