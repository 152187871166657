<div class="funding-selection-card" *transloco="let t">
	<section [attr.id]="id" class="payment-method" [opTagClick]="paymentMethodTitle">
		<div *ngIf="id === 'debit_card'" class="debit-card-lock op-dark-green op-mb-16">
			<div class="op-icon-align-label-font op-label-bold-font">
				<mat-icon svgIcon="op-lightning-solid"></mat-icon>
				<span> {{ t('DISBURSEMENT_SELECTION.DEBIT_CARD.UNLOCK.fastestFunding') }} </span>
			</div>
		</div>
		<div class="card-header" *ngIf="!hideHeader" (click)="addBankAccount()">
			<div class="title left-align" [ngClass]="{ 'cursor-pointer': id !== 'ach' || !hasBankAccount }">
				<div class="title-icon op-mr-10" *ngIf="id !== 'ach' || !hasBankAccount">
					<mat-icon svgIcon="{{ paymentMethodIcon }}"></mat-icon>
				</div>
				<div class="title-text op-ml-5">
					<span class="op-black-bold">{{ paymentMethodTitle }}</span>
					<span class="op-dark-grey op-body-2-font">{{ paymentMethodDuration }}</span>
				</div>
				<div class="title-arrow" *ngIf="id !== 'ach' || !hasBankAccount">
					<mat-icon svgIcon="op-chevron-right"></mat-icon>
				</div>
			</div>
		</div>
		<div class="section-content" *ngIf="id !== 'ach' || groupedAccountList?.size || hasBankAccount">
			<ng-container *ngFor="let bankAccount of groupedAccountList | keyvalue">
				<div
					*ngFor="let account of bankAccount.value"
					class="section-group"
					[ngClass]="{ 'section-group-pt-1': !hideHeader }"
					(click)="selectBankAccount(account)"
				>
					<div class="title-icon op-mr-10">
						<op-bank-icon [bankName]="bankAccount.key"></op-bank-icon>
					</div>
					<div class="section-group-info op-ml-5">
						<div class="bank-info">
							<div class="op-black-bold">{{ bankAccount.key }}</div>
							<div class="op-body-1-font op-light-black op-mtb-4 bank-account-info">
								<div class="account-info op-label-font">
									{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.TYPE.' + account.accountType) }}
									{{ account.accountNumber | opLastNNumber: '.':3 }}
								</div>
							</div>
						</div>
						<div class="title-arrow op-black"><mat-icon svgIcon="op-chevron-right"></mat-icon></div>
					</div>
				</div>
			</ng-container>
			<div
				class="section-group"
				*ngIf="hasBankAccount && !hideHeader"
				[ngClass]="{ 'section-group-pt-1': hasBankAccount }"
				(click)="selectBankAccount()"
			>
				<div class="title-icon op-mr-10">
					<mat-icon svgIcon="op-plus-gray"></mat-icon>
				</div>
				<div class="section-group-info op-ml-5">
					<div class="bank-info">
						<div class="op-black-bold">{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.bankAccount') }}</div>
					</div>
					<div class="title-arrow op-black"><mat-icon svgIcon="op-chevron-right"></mat-icon></div>
				</div>
			</div>

			<div
				*ngIf="id === 'debit_card' && debitCardLocked && paymentMethodNote"
				class="op-label-font op-mt-16 op-pt-16 op-gray unlock-card"
			>
				<span>{{ paymentMethodNote }}</span>
			</div>
			<div *ngIf="id === 'check' && paymentMethodNote" class="op-label-font op-mt-16 op-pt-16 op-gray unlock-card">
				<span>{{ paymentMethodNote }}</span>
			</div>

			<div
				*ngIf="id === 'debit_card' && debitCardDetails && !debitCardLocked"
				class="op-label-font op-mt-16 op-pt-16 op-gray unlock-card"
			>
				{{ t('DISBURSEMENT_SELECTION.DEBIT_CARD.title') }}
				{{ debitCardDetails.last4 | opLastNNumber: '.':3 }}
			</div>
		</div>
	</section>
</div>
