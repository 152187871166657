<div class="esign-confirm-term" *transloco="let t">
	<div class="esign-title">
		<op-masthead [title]="t('ESIGN_CONFIRM_TERM.title')"></op-masthead>
	</div>
	<op-content-container class="content">
		<div class="op-mb-4">
			<div class="op-body-1-bold-font">
				<span>{{ t('ESIGN_CONFIRM_TERM.asOf') }}</span>
				<span>{{ currentDate }}</span>
			</div>
		</div>
		<span class="op-body-1-font op-mb-8">{{ t('ESIGN_CONFIRM_TERM.initial2') }}</span>

		<div id="toMakeChangesClick" class="op-label-font" *ngIf="onlineNotifyEnabled">
			{{ t('ESIGN_CONFIRM_TERM.toMakeChangesClick') }}
		</div>
		<div *ngIf="termRecalculated" id="termChangeWarning">
			<!-- <info-box nls="ESIGN_CONFIRM_TERM.termChangeWarning" level="info"></info-box> -->
		</div>
		<!-- GCP -->
		<div *ngIf="isGCP" class="op-mt-16 gcp op-body-2-bold-font">
			<div class="gcp-headers" *ngIf="!useTermTableWithPrincipalAmount; else gcpHeaderAmountFinanced">
				<div id="GCPLoanAmountDiv">
					{{ t('ESIGN_CONFIRM_TERM.GCPLoanAmount') }}
				</div>
				<div></div>
				<div id="GCPExistingBalanceDiv">
					{{ t('ESIGN_CONFIRM_TERM.GCPExistingBalance') }}
				</div>
				<div></div>
				<div id="GCPDepositedAmountDiv">
					{{ t('ESIGN_CONFIRM_TERM.GCPDepositedAmount') }}
				</div>
			</div>
			<div class="gcp-values">
				<div id="gcpValuesLoanAmountDiv">
					{{ esignUser?.loanAmount | currency: 'USD':'symbol':'0.2' }}
				</div>
				<div>=</div>
				<div id="gcpValuesExistingBalanceDiv">
					{{ esignUser?.existingBalance | currency: 'USD':'symbol':'0.2' }}
				</div>
				<div>+</div>
				<div id="gcpValuesDepositedAmountDiv" class="op-green">
					{{ esignUser?.depositedAmount | currency: 'USD':'symbol':'0.2' }}
				</div>
			</div>
		</div>

		<div
			class="op-mt-16 loan-terms op-label-font"
			*ngIf="!useTermTableWithPrincipalAmount; else termTableWithPrincipalAmount"
		>
			<div class="loan-term-item">
				<div id="loanTypeDiv" class="loan-term-item-title">
					{{ t('ESIGN_CONFIRM_TERM.loanType') }}
				</div>
				<div *ngIf="isSPL" id="loanTypeSPLDiv" class="loan-term-item-text">
					{{ t('ESIGN_CONFIRM_TERM.securedPersonalLoan') }}
				</div>
				<div *ngIf="!isSPL" id="loanTypeSPLDiv" class="loan-term-item-text">
					{{ t('ESIGN_CONFIRM_TERM.personalLoan') }}
				</div>
			</div>

			<div *ngIf="isSPL" class="loan-term-item">
				<div id="loanTermsVehicleDiv" class="loan-term-item-title">
					{{ t('ESIGN_CONFIRM_TERM.vehicle') }}
				</div>
				<div id="loanTermsVehicleInformationDiv" class="loan-term-item-text">
					{{ vehicleInformation }}
				</div>
			</div>

			<div *ngIf="!isGCP" class="loan-term-item">
				<div id="loanTermItemLoanAmountDiv" class="loan-term-item-title">
					{{ t('ESIGN_CONFIRM_TERM.loanAmount') }}
				</div>
				<div id="loanTermItemLoanAmountDiv2" class="loan-term-item-text">
					{{ esignUser?.loanAmount | currency: 'USD':'symbol':'0.2' }}
				</div>
			</div>

			<div class="loan-term-item">
				<div id="loanTermItemPaymentAmount" class="loan-term-item-title">
					{{ t('ESIGN_CONFIRM_TERM.paymentAmount') }}
				</div>
				<div id="loanTermItemPaymentAmountNote" class="loan-term-item-text">
					{{ esignUser?.paymentAmount | currency: 'USD':'symbol':'0.2' }}<br />
					<div>{{ t('ESIGN_CONFIRM_TERM.paymentAmountNote') }}</div>
				</div>
			</div>

			<div class="loan-term-item">
				<div id="loanTermItemPaymentFrequency" class="loan-term-item-title">
					{{ t('ESIGN_CONFIRM_TERM.paymentFrequency') }}
				</div>
				<div class="loan-term-item-text">
					<div id="semiMonthlyDiv" *ngIf="isSemiMonthly">{{ t('ESIGN_CONFIRM_TERM.semiMonthly') }}</div>
					<div id="biWeeklyDiv" *ngIf="isBiWeekly">{{ t('ESIGN_CONFIRM_TERM.biWeekly') }}</div>
					<div id="monthlyDiv" *ngIf="isMonthly">{{ t('ESIGN_CONFIRM_TERM.monthly') }}</div>
				</div>
			</div>

			<div class="loan-term-item">
				<div id="loanTermItemPaymentDay" class="loan-term-item-title">
					{{ t('ESIGN_CONFIRM_TERM.paymentDay') }}
				</div>
				<div class="loan-term-item-text">
					<div *ngIf="isMonthly" id="loanTermItemTextPaymentDayMonthly">
						{{
							t('ESIGN_CONFIRM_TERM.paymentDayMonthly', {
								date1: esignUser?.paymentDayMonthly | opDayOrdinal: language
							})
						}}
					</div>
					<div *ngIf="isSemiMonthly" id="loanTermItemTextSemiMonthly">
						{{
							t('ESIGN_CONFIRM_TERM.paymentDaySemiMonthlyText', {
								date1: esignUser?.paymentDaySemiMonthlyValue1 | opDayOrdinal: language,
								date2: esignUser?.paymentDaySemiMonthlyValue2 | opDayOrdinal: language
							})
						}}
					</div>
					<div *ngIf="isBiWeekly" id="loanTermItemTextBiWeekly">
						{{ t('ESIGN_CONFIRM_TERM.paymentDayBiWeeklyText1') }}{{ esignUser?.paymentDay }}
					</div>
				</div>
			</div>

			<div class="loan-term-item">
				<div class="loan-term-item-title" id="loanTermItemPaymentTerm">
					{{ t('ESIGN_CONFIRM_TERM.paymentTerm') }}
				</div>
				<div class="loan-term-item-text" id="loanTermItemTextPaymentTerm">
					{{ esignUser?.paymentTerm }}&nbsp; {{ t('ESIGN_CONFIRM_TERM.months') }}
				</div>
			</div>

			<div class="loan-term-item">
				<div class="loan-term-item-title" id="loanTermItemNumberOfPayments">
					{{ t('ESIGN_CONFIRM_TERM.numberOfPayments') }}
				</div>
				<div class="loan-term-item-text">
					<div *ngIf="!isPaymentsError" aria-live="assertive" id="loanTermItemTextNumberOfPayments">
						{{ 'ESIGN_CONFIRM_TERM.totalPayments' | transloco: { numberOfPayments: esignUser?.numberOfPayments } }}
					</div>
					<div *ngIf="isPaymentsError" id="loanTermItemError" aria-live="assertive">ERROR</div>
				</div>
			</div>

			<div class="loan-term-item">
				<div class="loan-term-item-title" id="loanTermItemApr">
					{{ t('ESIGN_CONFIRM_TERM.apr') }}
				</div>
				<div class="loan-term-item-text" id="loanTermItemTextApr">
					<op-info-tip id="aprNoteTip" [key]="t('ESIGN_CONFIRM_TERM.aprNote')" icon="op-help">
						<span>{{ esignUser?.apr }}</span>
					</op-info-tip>
				</div>
			</div>

			<div class="loan-term-item" *ngIf="loanTerms?.feeType">
				<div class="loan-term-item-title" id="loanTermItemOriginationFee">
					{{ t('ESIGN_CONFIRM_TERM.originationFee') }}
				</div>
				<div class="loan-term-item-text">
					<op-info-tip
						id="originationFeeTip"
						icon="op-help"
						[key]="
							t('ESIGN_CONFIRM_TERM.originationFeeNote', {
								originationFeePercent: esignUser.originationFeePercent | percent: '1.0-2',
								faqLink: faqLink
							})
						"
					>
						<span>{{ esignUser?.originationFee | currency: 'USD':'symbol':'0.2' }} </span>
					</op-info-tip>
				</div>
			</div>

			<div class="loan-term-item" *ngIf="loanTerms?.feeDescription1 === 'stampTax'">
				<div class="loan-term-item-title" id="loanTermItemStampTax">
					{{ t('ESIGN_CONFIRM_TERM.stampTax') }}
				</div>
				<div class="loan-term-item-text" id="loanTermItemTextStampTax">
					<span>{{ esignUser?.stampTax | currency: 'USD':'symbol':'0.2' }}</span>
				</div>
			</div>

			<div class="loan-term-item" *ngIf="esignUser?.accountType && showAch">
				<div class="loan-term-item-title" id="loanTermItemDepositFundsTo">
					{{ t('ESIGN_CONFIRM_TERM.depositFundsTo') }}
				</div>
				<div class="loan-term-item-text" id="loanTermItemTextBankAccount">
					{{ 'ESIGN_CONFIRM_TERM.bankAccount' | transloco: { accountNumber: esignUser?.accountNumber } }}
					<op-info-tip id="fundsInNoteTip" [key]="t('ESIGN_CONFIRM_TERM.fundsAvailableInNote')">
						<span>{{ t('ESIGN_CONFIRM_TERM.fundsAvailableIn') }}</span>
					</op-info-tip>
				</div>
			</div>
			<!--Debit Card-->
			<div class="loan-term-item" *ngIf="fundsAvailability">
				<div class="loan-term-item-title" id="loanTermItemDepositFundsTo">
					{{ t('ESIGN_CONFIRM_TERM.depositFundsTo') }}
				</div>
				<div class="loan-term-item-text" id="loanTermItemTextBankAccount">
					{{ 'ESIGN_CONFIRM_TERM.debitCard' | transloco: { accountNumber: last4Debit } }}
				</div>
			</div>
			<!--Debit Card-->

			<div *ngIf="rachAccountNumber && showAch" class="loan-term-item">
				<div class="loan-term-item-title" id="rachAutopaymentFrom">
					{{ t('ESIGN_CONFIRM_TERM.autopaymentFrom') }}
				</div>
				<div class="loan-term-item-text" id="rachAccountNumber">
					{{ 'ESIGN_CONFIRM_TERM.bankAccount' | transloco: { accountNumber: rachAccountNumber } }}
					<br />
					<div>
						{{ esignUser?.paymentAmount | currency: 'USD':'symbol':'0.2' }}
						<span *ngIf="isSemiMonthly">
							{{ t('ESIGN_CONFIRM_TERM.paymentDaySemiMonthlyTextPrefix') }}
							{{
								t('ESIGN_CONFIRM_TERM.paymentDaySemiMonthlyText', {
									date1: esignUser?.paymentDaySemiMonthlyValue1,
									date2: paymentDaySemiMonthlyValue2
								})
							}}
						</span>
						<span *ngIf="isBiWeekly" id="autopaymentBiWeeklyDiv">
							{{ t('ESIGN_CONFIRM_TERM.autopaymentBiWeekly') }}{{ esignUser?.paymentDay }}
						</span>
					</div>
					<div id="autopaymentCancelDiv">{{ t('ESIGN_CONFIRM_TERM.autopaymentCancel') }}</div>
				</div>
			</div>
		</div>

		<div *ngIf="hasMultipleValidProductOffers" id="confirmedProductSelectionDisclaimerDiv" class="op-micro-font">
			*{{ 'ESIGN_CONFIRM_TERM.confirmedProductSelectionDisclaimer' | transloco }}
		</div>
		<br />

		<span *ngIf="fundsAvailability" class="op-micro-font esign-initial">{{ t(fundingInfo) }}</span>

		<div class="esign-alert-content">
			<op-alert level="WARNING">
				<span class="op-label-font esign-alert">{{ t('ESIGN_CONFIRM_TERM.alert') }}</span>
			</op-alert>
		</div>

		<button
			mat-flat-button
			class="padding-for-long-name"
			color="primary"
			id="confirmTermNextBtn"
			type="button"
			(click)="onSubmit()"
			[opTagClick]="'Confirm Terms & Continue'"
		>
			{{ t('ESIGN_CONFIRM_TERM.next') }}
		</button>
		<button
			mat-flat-button
			class="padding-for-long-name"
			color="secondary"
			id="confirmTermBackBtn"
			type="button"
			[routerLink]="['/adjust-terms']"
			[opTagClick]="'Go back to adjust terms'"
		>
			{{ t('ESIGN_CONFIRM_TERM.toAdjustTerms') }}
		</button>
	</op-content-container>

	<ng-template #gcpHeaderAmountFinanced>
		<div class="gcp-headers">
			<div id="GCPLoanAmountDiv">
				{{ t('ESIGN_CONFIRM_TERM.GCPAmountFinanced') }}
			</div>
			<div></div>
			<div id="GCPExistingBalanceDiv">
				{{ t('ESIGN_CONFIRM_TERM.GCPExistingBalance') }}
			</div>
			<div></div>
			<div id="GCPDepositedAmountDiv">
				{{ t('ESIGN_CONFIRM_TERM.GCPLoanProceedsGiven') }}
			</div>
		</div>
	</ng-template>

	<ng-template #termTableWithPrincipalAmount>
		<div class="op-label-font loan-terms">
			<div class="loan-term-item">
				<div id="loanTypeDiv" class="loan-term-item-title">
					{{ t('ESIGN_CONFIRM_TERM.loanType') }}
				</div>
				<div *ngIf="isSPL" id="loanTypeSPLDiv" class="loan-term-item-text">
					{{ t('ESIGN_CONFIRM_TERM.securedPersonalLoan') }}
				</div>
				<div *ngIf="!isSPL" id="loanTypeSPLDiv" class="loan-term-item-text">
					{{ t('ESIGN_CONFIRM_TERM.personalLoan') }}
				</div>
			</div>

			<div *ngIf="isSPL" class="loan-term-item">
				<div id="loanTermsVehicleDiv" class="loan-term-item-title">
					{{ t('ESIGN_CONFIRM_TERM.vehicle') }}
				</div>
				<div id="loanTermsVehicleInformationDiv" class="loan-term-item-text">
					{{ vehicleInformation }}
				</div>
			</div>

			<div class="loan-term-item">
				<div id="loanTermItemTotalLoanAmountDiv" class="loan-term-item-title">
					{{ t('ESIGN_CONFIRM_TERM.totalLoanAmount') }}
				</div>
				<div id="loanTermItemLoanAmountDiv2" class="loan-term-item-text">
					{{ esignUser?.principalAmount | currency: 'USD':'symbol':'0.2' }}
				</div>
			</div>

			<div class="loan-term-item" *ngIf="loanTerms?.feeType">
				<div class="loan-term-item-title" id="loanTermItemOriginationFee">
					{{ t('ESIGN_CONFIRM_TERM.originationFee') }}
				</div>
				<div class="loan-term-item-text">
					<op-info-tip
						id="originationFeeTip"
						icon="op-help"
						[key]="
							t('ESIGN_CONFIRM_TERM.originationFeeNote', {
								originationFeePercent: esignUser.originationFeePercent | percent: '1.0-2',
								faqLink: faqLink
							})
						"
					>
						<span>{{ esignUser?.originationFee | currency: 'USD':'symbol':'0.2' }} </span>
					</op-info-tip>
				</div>
			</div>

			<div class="loan-term-item">
				<div id="loanTermItemLoanAmountDiv" class="loan-term-item-title">
					{{ t('ESIGN_CONFIRM_TERM.amountFinanced') }}
				</div>
				<div id="loanTermItemLoanAmountDiv2" class="loan-term-item-text">
					{{ esignUser?.loanAmount | currency: 'USD':'symbol':'0.2' }}
				</div>
			</div>

			<div class="loan-term-item">
				<div id="loanTermItemPaymentAmount" class="loan-term-item-title">
					{{ t('ESIGN_CONFIRM_TERM.paymentAmount') }}
				</div>
				<div id="loanTermItemPaymentAmountNote" class="loan-term-item-text">
					{{ esignUser?.paymentAmount | currency: 'USD':'symbol':'0.2' }}<br />
					<div>{{ t('ESIGN_CONFIRM_TERM.paymentAmountNote') }}</div>
				</div>
			</div>

			<div class="loan-term-item">
				<div id="loanTermItemPaymentFrequency" class="loan-term-item-title">
					{{ t('ESIGN_CONFIRM_TERM.paymentFrequency') }}
				</div>
				<div class="loan-term-item-text">
					<div id="semiMonthlyDiv" *ngIf="isSemiMonthly">{{ t('ESIGN_CONFIRM_TERM.semiMonthly') }}</div>
					<div id="biWeeklyDiv" *ngIf="isBiWeekly">{{ t('ESIGN_CONFIRM_TERM.biWeekly') }}</div>
					<div id="monthlyDiv" *ngIf="isMonthly">{{ t('ESIGN_CONFIRM_TERM.monthly') }}</div>
				</div>
			</div>

			<div class="loan-term-item">
				<div id="loanTermItemPaymentDay" class="loan-term-item-title">
					{{ t('ESIGN_CONFIRM_TERM.paymentDay') }}
				</div>
				<div class="loan-term-item-text">
					<div *ngIf="isMonthly" id="loanTermItemTextPaymentDayMonthly">
						{{
							t('ESIGN_CONFIRM_TERM.paymentDayMonthly', {
								date1: esignUser?.paymentDayMonthly | opDayOrdinal: language
							})
						}}
					</div>
					<div *ngIf="isSemiMonthly" id="loanTermItemTextSemiMonthly">
						{{
							t('ESIGN_CONFIRM_TERM.paymentDaySemiMonthlyText', {
								date1: esignUser?.paymentDaySemiMonthlyValue1 | opDayOrdinal: language,
								date2: esignUser?.paymentDaySemiMonthlyValue2 | opDayOrdinal: language
							})
						}}
					</div>
					<div *ngIf="isBiWeekly" id="loanTermItemTextBiWeekly">
						{{ t('ESIGN_CONFIRM_TERM.paymentDayBiWeeklyText1') }}{{ esignUser?.paymentDay }}
					</div>
				</div>
			</div>

			<div class="loan-term-item">
				<div class="loan-term-item-title" id="loanTermItemPaymentTerm">
					{{ t('ESIGN_CONFIRM_TERM.paymentTerm') }}
				</div>
				<div class="loan-term-item-text" id="loanTermItemTextPaymentTerm">
					{{ esignUser?.paymentTerm }}&nbsp; {{ t('ESIGN_CONFIRM_TERM.months') }}
				</div>
			</div>

			<div class="loan-term-item">
				<div class="loan-term-item-title" id="loanTermItemNumberOfPayments">
					{{ t('ESIGN_CONFIRM_TERM.numberOfPayments') }}
				</div>
				<div class="loan-term-item-text">
					<div *ngIf="!isPaymentsError" aria-live="assertive" id="loanTermItemTextNumberOfPayments">
						{{ 'ESIGN_CONFIRM_TERM.totalPayments' | transloco: { numberOfPayments: esignUser?.numberOfPayments } }}
					</div>
					<div *ngIf="isPaymentsError" id="loanTermItemError" aria-live="assertive">ERROR</div>
				</div>
			</div>

			<div class="loan-term-item">
				<div class="loan-term-item-title" id="loanTermItemApr">
					{{ t('ESIGN_CONFIRM_TERM.apr') }}
				</div>
				<div class="loan-term-item-text" id="loanTermItemTextApr">
					<op-info-tip id="aprNoteTip" [key]="t('ESIGN_CONFIRM_TERM.aprNote')" icon="op-help">
						<span>{{ esignUser?.apr }}</span>
					</op-info-tip>
				</div>
			</div>

			<div class="loan-term-item" *ngIf="loanTerms?.feeDescription1 === 'stampTax'">
				<div class="loan-term-item-title" id="loanTermItemStampTax">
					{{ t('ESIGN_CONFIRM_TERM.stampTax') }}
				</div>
				<div class="loan-term-item-text" id="loanTermItemTextStampTax">
					<span>{{ esignUser?.stampTax | currency: 'USD':'symbol':'0.2' }}</span>
				</div>
			</div>

			<div class="loan-term-item" *ngIf="esignUser?.accountType && showAch">
				<div class="loan-term-item-title" id="loanTermItemDepositFundsTo">
					{{ t('ESIGN_CONFIRM_TERM.depositFundsTo') }}
				</div>
				<div class="loan-term-item-text" id="loanTermItemTextBankAccount">
					{{ 'ESIGN_CONFIRM_TERM.bankAccount' | transloco: { accountNumber: esignUser?.accountNumber } }}
					<op-info-tip id="fundsInNoteTip" [key]="t('ESIGN_CONFIRM_TERM.fundsAvailableInNote')">
						<span>{{ t('ESIGN_CONFIRM_TERM.fundsAvailableIn') }}</span>
					</op-info-tip>
				</div>
			</div>

			<!--Debit Card-->
			<div class="loan-term-item" *ngIf="fundsAvailability">
				<div class="loan-term-item-title" id="loanTermItemDepositFundsTo">
					{{ t('ESIGN_CONFIRM_TERM.depositFundsTo') }}
				</div>
				<div class="loan-term-item-text" id="loanTermItemTextBankAccount">
					{{ 'ESIGN_CONFIRM_TERM.debitCard' | transloco: { cardNumber: last4Debit } }}
				</div>
			</div>
			<!--Debit Card-->

			<div *ngIf="rachAccountNumber && showAch" class="loan-term-item">
				<div class="loan-term-item-title" id="rachAutopaymentFrom">
					{{ t('ESIGN_CONFIRM_TERM.autopaymentFrom') }}
				</div>
				<div class="loan-term-item-text" id="rachAccountNumber">
					{{ 'ESIGN_CONFIRM_TERM.bankAccount' | transloco: { accountNumber: rachAccountNumber } }}
					<br />
					<div>
						{{ esignUser?.paymentAmount | currency: 'USD':'symbol':'0.2' }}
						<span *ngIf="isSemiMonthly">
							{{ t('ESIGN_CONFIRM_TERM.paymentDaySemiMonthlyTextPrefix') }}
							{{
								t('ESIGN_CONFIRM_TERM.paymentDaySemiMonthlyText', {
									date1: esignUser?.paymentDaySemiMonthlyValue1,
									date2: paymentDaySemiMonthlyValue2
								})
							}}
						</span>
						<span *ngIf="isBiWeekly" id="autopaymentBiWeeklyDiv">
							{{ t('ESIGN_CONFIRM_TERM.autopaymentBiWeekly') }}{{ esignUser?.paymentDay }}
						</span>
					</div>
					<div id="autopaymentCancelDiv">{{ t('ESIGN_CONFIRM_TERM.autopaymentCancel') }}</div>
				</div>
			</div>
		</div>
	</ng-template>
	<div class="op-pt-40"></div>
</div>
