import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PlaidLinkService } from 'src/app/core/services/plaid-link/plaid-link.service';
import { opRequired } from '../../decorators/required.decorator';

@Component({
	selector: 'op-error-panel',
	templateUrl: './op-error-panel.component.html',
	styleUrls: ['./op-error-panel.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ErrorPanelComponent {
	@Input()
	@opRequired()
	errorMessage: string = '';
}
