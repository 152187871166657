import { Component, EventEmitter, Input, Output } from '@angular/core';
import { opRequired } from 'src/app/shared/decorators/required.decorator';
import { IAchBankAccount } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { IDebitCardDetails } from 'src/app/core/services/mobile-api';

@Component({
	selector: 'op-funding-selection-card',
	templateUrl: './funding-selection-card.component.html',
	styleUrls: ['./funding-selection-card.component.scss']
})
export class FundingSelectionCardComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	hideHeader: boolean = false;

	@Input()
	paymentMethodIcon: string;

	@Input()
	paymentMethodTitle: string;

	@Input()
	paymentMethodDuration: string;

	@Input()
	paymentMethodNote: string;

	@Input()
	hasBankAccount: boolean = false;

	@Input()
	debitCardLocked: boolean = false;

	@Input()
	debitCardDetails: IDebitCardDetails;

	@Output()
	bankAccountSelected = new EventEmitter<IAchBankAccount>();

	@Input()
	groupedAccountList: Map<string, IAchBankAccount[]>;

	selectBankAccount(account: IAchBankAccount | undefined = undefined): void {
		if (this.hideHeader || (!this.hasBankAccount && !account?.id)) {
			return;
		}
		this.bankAccountSelected.emit(account);
	}

	addBankAccount(): void {
		if (!this.hasBankAccount) {
			this.bankAccountSelected.emit(null);
		}
	}
}
