import { every } from 'lodash';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';

import { ApplicantUtils } from '../../applicant/applicant-utils';
import IProduct, {
	ProductCategoriesEnum,
	ProductStatusesEnum,
	ProductSubStatusesEnum,
	validProductStatusesList,
	validSplProductStatusesListWithVehicle
} from '../product/product.model';

export class ProductOfferDetailsUtils {
	isSkipVehicleForNow: boolean;
	static fromLoanApp(loanApp: ILoanApplication): ProductOfferDetailsUtils {
		if (loanApp?.productOfferDetails?.length > 0) {
			return new this(loanApp.productOfferDetails, ApplicantUtils.fromLoanApp(loanApp));
		} else if (loanApp?.preApprovedTerms?.length > 0) {
			const preApprovedTermsArr: IProduct[] = loanApp.preApprovedTerms;
			let keyArr = Object.keys(ProductStatusesEnum).filter((x) => ProductStatusesEnum[x] === loanApp.applicationStatus);
			preApprovedTermsArr[0].productStatus = ProductStatusesEnum[keyArr[0]];
			return new this(preApprovedTermsArr || <IProduct[]>[], ApplicantUtils.fromLoanApp(loanApp));
		} else {
			return new this([], new ApplicantUtils(null));
		}
	}

	constructor(readonly products: IProduct[], readonly applicant: ApplicantUtils) {
		this.isSkipVehicleForNow = this.applicant?.isSkipVehicleForNow();
	}

	get numberOfOffers(): number {
		return this.products.length;
	}

	get productOfferDetails(): IProduct[] {
		return this.products;
	}

	/**
	 * It's true as long as there is an SPL product AND
	 * either a eligible vehicle is given OR an inEligible vehicle is given but skip_for_now option is selected
	 * @returns
	 */
	public getSecuredPersonalLoanProduct(): IProduct {
		return this.products.find(
			(product) =>
				product.productCategory === ProductCategoriesEnum.securedPersonalLoan &&
				(!Boolean(product.isIneligibleVehicleProvided) || Boolean(this.isSkipVehicleForNow))
		);
	}

	public hasIneligibleVehicle(): boolean {
		const spl = this.products.find((product) => product.productCategory === ProductCategoriesEnum.securedPersonalLoan);
		return spl?.isIneligibleVehicleProvided && !Boolean(this.isSkipVehicleForNow);
	}

	public getUnsecuredPersonalLoanProduct(): IProduct {
		return this.products.find((product) => product.productCategory === ProductCategoriesEnum.unsecuredPersonalLoan);
	}

	/**
	 * Determine if the product is of the Secured Personal Loan category.
	 *
	 * @param {IProduct} product
	 * @memberof ProductOfferDetailsUtils
	 */
	public isSecuredPersonalLoan = (product: IProduct): boolean =>
		product.productCategory === ProductCategoriesEnum.securedPersonalLoan;

	/**
	 * Determine if the product is of the Unsecured Personal Loan category.
	 *
	 * @param {IProduct} product
	 * @memberof ProductOfferDetailsUtils
	 */
	public isUnsecuredPersonalLoan = (product: IProduct): boolean =>
		product.productCategory === ProductCategoriesEnum.unsecuredPersonalLoan;

	/**
	 * Determine if multiple products are being offered.
	 *
	 * @memberof ProductOfferDetailsUtils
	 */
	public hasMultipleProductsOffered = (): boolean => {
		const upl = this.getUnsecuredPersonalLoanProduct();
		const spl = this.getSecuredPersonalLoanProduct();
		return Boolean(upl) && Boolean(spl);
	};

	/**
	 * Determine if there is ateast one offer in Product offer details and not declined.
	 *
	 * @memberof ProductOfferDetailsUtils
	 */
	public hasAtleastOneLoan = (): boolean => {
		const upl = this.getUnsecuredPersonalLoanProduct();
		const spl = this.getSecuredPersonalLoanProduct();
		return Boolean(upl) || Boolean(spl);
	};

	/**
	 * Determine if multiple products are in a valid status.
	 *
	 * @memberof ProductOfferDetailsUtils
	 */
	public hasMultipleValidProductOffers = (): boolean => {
		const securedPersonalLoan = this.getSecuredPersonalLoanProduct();
		const unsecuredPersonalLoan = this.getUnsecuredPersonalLoanProduct();
		return (
			this.hasMultipleProductsOffered() &&
			every([securedPersonalLoan, unsecuredPersonalLoan], this.hasValidProductStatus)
		);
	};

	/**
	 * checks if the secured personal loan has a valid vehicle and is in valid status, pre-approved or approved
	 * @returns boolean
	 */
	public hasValidSPLOfferWithVehicle = (): boolean => {
		const securedPersonalLoan = this.getSecuredPersonalLoanProduct();
		return validSplProductStatusesListWithVehicle.includes(securedPersonalLoan?.productStatus);
	};

	/**
	 * Checks is one or more loans is in valid status
	 *
	 * @returns
	 */
	public isAllLoansHaveValidStatus = (): boolean => {
		const productList = [];
		const securedPersonalLoan = this.getSecuredPersonalLoanProduct();
		if (securedPersonalLoan) {
			productList.push(securedPersonalLoan);
		}

		const unsecuredPersonalLoan = this.getUnsecuredPersonalLoanProduct();
		if (unsecuredPersonalLoan) {
			productList.push(unsecuredPersonalLoan);
		}
		return productList.length > 0 && every(productList, this.hasValidProductStatus);
	};

	/**
	 * Determine if a Unsecured Personal loan is being offered and if it is in the APPROVED status.
	 *
	 * @memberof ProductOfferDetailsUtils
	 */
	public hasApprovedPersonalLoan = (): boolean =>
		this.getUnsecuredPersonalLoanProduct()?.productStatus === ProductStatusesEnum.approved;

	/**
	 * Determine if a Secured Personal loan is being offered and if it is in the ELIGIBLE status.
	 *
	 * @memberof ProductOfferDetailsUtils
	 */
	public hasEligibleSecuredPersonalLoan = (): boolean =>
		this.getSecuredPersonalLoanProduct()?.productStatus === ProductStatusesEnum.eligible;

	/**
	 * Determine if a Secured Personal loan is being offered and if it is in the PRE_APPROVED status.
	 *
	 * @memberof ProductOfferDetailsUtils
	 */
	public hasPreApprovedSecuredPersonalLoan = (): boolean =>
		this.getSecuredPersonalLoanProduct()?.productStatus === ProductStatusesEnum.preApproved;

	/**
	 * Determine if a Secured Personal loan is being offered.
	 *
	 * @memberof ProductOfferDetailsUtils
	 */
	public hasSecuredPersonalLoan = (): boolean => Boolean(this.getSecuredPersonalLoanProduct());

	/**
	 * Determine if a particular product category has a valid product status.
	 *
	 * @param {IProduct} product
	 * @memberof ProductOfferDetailsUtils
	 */
	public hasValidProductStatus(product: IProduct): boolean {
		return validProductStatusesList.includes(product?.productStatus);
	}

	/**
	 * Return the loanObject matching the product category and sub-status
	 *
	 * @param category
	 * @param subStatus
	 */
	public findProductSubStatus(category: ProductCategoriesEnum, subStatus: ProductSubStatusesEnum): IProduct {
		return this.products.find(
			(product) => product.productCategory === category && product.productSubStatus === subStatus
		);
	}

	/**
	 * Return the loanObject matching the product category and status–
	 *
	 * @param category
	 * @param status
	 */
	public getLoanObject(category: ProductCategoriesEnum, status: ProductStatusesEnum): IProduct {
		return this.products.find((product) => product.productCategory === category && product.productStatus === status);
	}

	/**
	 * Determine if all products offered are in approved status.
	 *
	 *
	 * @memberof ProductOfferDetailsUtils
	 */
	public checkIfAllProductsAreApproved(): boolean {
		return every(this.products, this.isApprovedProductObject);
	}

	public isAnyProductInStatus(status: ProductStatusesEnum): boolean {
		return this.products.some((product) => product.productStatus === status);
	}
	/**
	 * Determine if only single product is being offered.
	 *
	 * @memberof ProductOfferDetailsUtils
	 */
	public hasSingleProductOffered(): boolean {
		return (
			this.products.length === 1 ||
			(this.products.length === 2 && this.getUnsecuredPersonalLoanProduct() && !this.getSecuredPersonalLoanProduct())
		);
	}

	public hasOnlyUPL(): boolean {
		return this.products.length === 1 && this.isUnsecuredPersonalLoan(this.products[0]);
	}

	public hasOnlySPL(): boolean {
		return this.products.length === 1 && this.isSecuredPersonalLoan(this.products[0]);
	}

	public isApprovedProductObject(product: IProduct): boolean {
		return ProductStatusesEnum.approved === product.productStatus;
	}

	/**
	 * Determine if a product is in approved status.
	 *
	 *
	 * @memberof ProductOfferDetailsUtils
	 */
	public isApprovedProduct(category: ProductCategoriesEnum): boolean {
		return this.products.some(
			(product) => product.productCategory === category && ProductStatusesEnum.approved === product.productStatus
		);
	}

	public isPreApprovedProduct(category: ProductCategoriesEnum): boolean {
		return this.products.some(
			(product) => product.productCategory === category && ProductStatusesEnum.preApproved === product.productStatus
		);
	}
	public isBouncedProduct(category: ProductCategoriesEnum): boolean {
		return this.products.some(
			(product) => product.productCategory === category && ProductStatusesEnum.bounced === product.productStatus
		);
	}

	/**
	 * This method returns true if all the products SPL and/or UPL are pre-approved, else returns false.
	 * @returns boolean
	 */
	public isAllProductsPreApproved(): boolean {
		return this.products.every((product) => ProductStatusesEnum.preApproved === product.productStatus);
	}
}
