import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import {
	combineLatest,
	filter,
	iif,
	map,
	mergeMap,
	Observable,
	of,
	Subject,
	Subscription,
	switchMap,
	takeUntil
} from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import {
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { IDebitCardDetails, MobileApiService } from 'src/app/core/services/mobile-api';

import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import {
	DisbursementChannelEnum,
	DisbursementType
} from 'src/app/core/services/loan-application/disbursement/disbursement.model';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';

import { CheckDeliveryConfirmComponent } from 'src/app/shared/components/dialogs/check-delivery-confirm/check-delivery-confirm.component';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';

import { MessageConfirmDialogComponent } from 'src/app/shared/components/dialogs/message-confirm-dialog/message-confirm-dialog.component';
import { FundingMethodConfirmComponent } from 'src/app/shared/components/dialogs/funding-method-confirm/funding-method-confirm.component';

@Component({
	selector: 'op-funding-selection',
	templateUrl: './funding-selection.component.html',
	styleUrls: ['./funding-selection.component.scss']
})
export class FundingSelectionComponent implements OnInit, OnDestroy {
	private subscription = new Subscription();
	private unsubscribed = new Subject<void>();

	readonly DisbursementType = DisbursementType;

	selectedVerifiedAccountId: number;

	eligibleForDebitCard: boolean = false;
	eligibleForDebitCardLock: boolean = false;

	showDebitCardError: boolean = false;
	showFundingOptions: boolean = false;
	debitCardDetails: IDebitCardDetails = null;
	previousSelection = null;
	unselectedAccountMap: Map<string, IAchBankAccount[]>;
	selectedAccountMap: Map<string, IAchBankAccount[]>;
	selectedBankAccount: IAchBankAccount;
	hasBankAccount: boolean = false;
	accountMap = new Map<string, IAchBankAccount[]>();
	bankAccounts: IAchBankAccount[] = [];

	constructor(
		private route: ActivatedRoute,
		private routingService: RoutingService,
		private dialogService: DialogService,
		private tagDataService: TagDataService,
		private translocoService: TranslocoService,
		private bankAccountService: AchBankAccountsService,
		private mobileApiService: MobileApiService,
		protected loanAppService: LoanApplicationService
	) {}

	ngOnInit() {
		this.showDebitCardError = this.route.snapshot.queryParamMap?.get('error') === 'debitCard';

		const fundingSub = combineLatest([
			this.loanAppService.loanApplication$,
			this.bankAccountService.bankAccounts$,
			this.loanAppService.isGCPOrReturnApplicant() ? this.bankAccountService.returningBankAccounts$ : of([])
		])
			.pipe(
				map(([loanApp, rsp, returningRsp]) => {
					const bankAccounts = rsp?.concat(returningRsp) || [];
					this.hasBankAccount = bankAccounts?.length > 0;
					this.selectFundingOption(loanApp, bankAccounts);
					return loanApp;
				}),
				filter((loanApp) => Boolean(loanApp)),
				distinctUntilChanged(),
				takeUntil(this.unsubscribed),
				mergeMap((loanApp) => {
					return this.mobileApiService.getDebitCardEligibility(loanApp.id, 0, {
						applicationType: loanApp.applicationType
					});
				}),
				tap((debitCardEligibility) => {
					this.eligibleForDebitCard = debitCardEligibility.eligible;
				}),
				mergeMap((debitCardEligibility) =>
					iif(
						() => debitCardEligibility.eligible || this.loanAppService.isGCPOrReturnApplicant(),
						of(debitCardEligibility),
						this.mobileApiService.getDebitCardLockEligibility(this.loanAppService.loanApplicationId).pipe(
							tap((debitCardLockEligibility) => {
								this.eligibleForDebitCardLock = debitCardLockEligibility.eligible;
							}),
							map(() => debitCardEligibility)
						)
					)
				),
				mergeMap((featureEligibility) => {
					return featureEligibility.eligible
						? this.mobileApiService.getDebitCard(this.loanAppService.loanApplicationId)
						: of(null);
				})
			)
			.subscribe({
				next: (debitCardDetails: IDebitCardDetails) => {
					this.showFundingOptions = true;
					this.debitCardDetails = debitCardDetails;
				}
			});
		this.subscription.add(fundingSub);
	}

	ngOnDestroy(): void {
		this.stopSubscription();
		this.subscription.unsubscribe();
	}

	accountSelected(bankId: number): void {
		this.selectedVerifiedAccountId = bankId;
	}

	onDebitCardSelection(): void {
		if (this.debitCardDetails) {
			this.captureEvent('funding method selected', DisbursementType.debit);
			this.showDebitCardDisbursementConfirm()
				.pipe(filter((result) => Boolean(result)))
				.subscribe({
					next: (result) => {
						this.routingService.route(RoutingPathsEnum.documentSubmit);
					}
				});
		} else {
			this.showDebitCardDisbursementAuthorization()
				.pipe(filter((result) => !!result))
				.subscribe({
					next: (result) => {
						if (this.eligibleForDebitCardLock) {
							this.captureEvent('unlock debit card disbursement');
							this.routingService.route(RoutingPathsEnum.fundingSelectionAddBankAccount);
						} else {
							this.captureEvent('funding method selected', DisbursementType.debit);
							this.routingService.route(RoutingPathsEnum.addDebitCard);
						}
					}
				});
		}
	}

	onBankSelection(bankAccount: IAchBankAccount): void {
		this.stopSubscription();
		if (bankAccount?.id) {
			this.mobileApiService
				.updateDisbursementAch(true, this.loanAppService.loanApplicationId)
				.pipe(
					mergeMap(() =>
						this.bankAccountService.selectBankAccount({ bankAccountId: bankAccount.id, customerConfirms: true })
					)
				)
				.subscribe({
					next: () => {
						this.routingService.route(RoutingPathsEnum.documentSubmit);
					}
				});
		} else {
			this.captureEvent('funding method selected', DisbursementType.ach);
			this.mobileApiService.updateDisbursementAch(true, this.loanAppService.loanApplicationId).subscribe({
				next: () => {
					this.routingService.route(RoutingPathsEnum.addBankAccount);
				}
			});
		}
	}

	onCheckSelection(): void {
		this.captureEvent('funding method selected', DisbursementType.check);
		this.openDenyAchDisbursementDialog()
			.pipe(
				switchMap((result) => {
					this.stopSubscription();
					return result
						? this.mobileApiService.updateDisbursementAch(false, this.loanAppService.loanApplicationId)
						: of(null);
				})
			)
			.subscribe({
				next: (result) => {
					if (result) {
						this.routingService.route(RoutingPathsEnum.documentSubmit);
					}
				}
			});
	}

	private openDenyAchDisbursementDialog(): Observable<any> {
		if (OrganizationUtils.isMetaBank(this.loanAppService.getLoanApp().issuingOrganization)) {
			return this.dialogService.openSimpleDialog(CheckDeliveryConfirmComponent);
		} else {
			return of(true);
		}
	}

	private groupBankAccount(bankAccounts): void {
		this.unselectedAccountMap = bankAccounts
			.filter((item) => item.id !== this.selectedVerifiedAccountId)
			.reduce((entryMap, e) => entryMap.set(e.bankName, [...(entryMap.get(e.bankName) || []), e]), new Map());
		this.selectedAccountMap = bankAccounts
			.filter((item) => item.id === this.selectedVerifiedAccountId)
			.reduce((entryMap, e) => entryMap.set(e.bankName, [...(entryMap.get(e.bankName) || []), e]), new Map());
		this.accountMap = bankAccounts.reduce(
			(entryMap, e) => entryMap.set(e.bankName, [...(entryMap.get(e.bankName) || []), e]),
			new Map()
		);
		this.selectedBankAccount = bankAccounts.find((item) => item.id === this.selectedVerifiedAccountId);
	}

	private selectFundingOption(loanApp: ILoanApplication, bankAccounts: IAchBankAccount[]): void {
		this.accountSelected(loanApp.disbursement.disbursementBankAccountId);
		if (loanApp.disbursement.disbursementType === DisbursementType.check) {
			this.previousSelection = {
				disbursementType: DisbursementType.check,
				id: 'check',
				paymentMethodTitle: this.translocoService.translate('DISBURSEMENT_SELECTION.CHECK.title'),
				paymentMethodDuration: this.translocoService.translate('DISBURSEMENT_SELECTION.CHECK.fundWithin'),
				paymentMethodNote: this.translocoService.translate('DISBURSEMENT_SELECTION.CHECK.info'),
				paymentMethodIcon: 'op-paperCheck'
			};
		}
		if (
			loanApp.disbursement.disbursementType === DisbursementType.ach &&
			loanApp.disbursement.disbursementBankAccountId
		) {
			this.previousSelection = {
				disbursementType: DisbursementType.ach,
				id: 'ach',
				hideHeader: true,
				paymentMethodTitle: this.selectedBankAccount?.bankName,
				paymentMethodIcon: 'op-bank'
			};
		}
		if (loanApp.disbursement.disbursementType === DisbursementType.debit) {
			this.previousSelection = {
				disbursementType: DisbursementType.debit,
				id: 'debit_card',
				paymentMethodTitle: this.translocoService.translate('DISBURSEMENT_SELECTION.DEBIT_CARD.title'),
				paymentMethodDuration: this.translocoService.translate('DISBURSEMENT_SELECTION.DEBIT_CARD.fundWithin'),
				paymentMethodNote: this.translocoService.translate(
					'DISBURSEMENT_SELECTION.DEBIT_CARD.UNLOCK.connectBankAccountToUseDebitCard'
				),
				paymentMethodIcon: 'op-atmCard'
			};
		}
		this.groupBankAccount(bankAccounts);
	}

	showDebitCardDisbursementConfirm() {
		return this.mobileApiService
			.updateDisbursementChannel(
				{
					disbursementChannel: DisbursementChannelEnum.online,
					disbursementType: DisbursementType.debit
				},
				this.loanAppService.loanApplicationId
			)
			.pipe(
				mergeMap(() => {
					return this.dialogService
						.openSimpleDialog(FundingMethodConfirmComponent, {
							data: {
								fundingOptions: DisbursementType.debit,
								card: {
									last4: this.debitCardDetails.last4,
									fundsAvailability: this.debitCardDetails.fundsAvailability
								}
							}
						})
						.pipe(
							map((result) => {
								if (result) {
									this.stopSubscription();
								}
								return !!result;
							})
						);
				})
			);
	}

	showDebitCardDisbursementAuthorization(): Observable<boolean> {
		const data = {
			title: this.translocoService.translate('DISBURSEMENT_SELECTION.DEBIT_CARD.AUTHORIZATION.title'),
			message: this.translocoService.translate('DISBURSEMENT_SELECTION.DEBIT_CARD.AUTHORIZATION.message'),
			confirmText: this.translocoService.translate('GLOBAL.continue')
		};
		this.captureEvent('show debit card disbursement authorization');
		return this.dialogService.openSimpleDialog(MessageConfirmDialogComponent, { data }).pipe(
			map((result) => {
				return !!result;
			})
		);
	}

	private stopSubscription(): void {
		this.unsubscribed.next();
		this.unsubscribed.complete();
	}

	continueWithPreviousSelection(): void {
		this.routingService.route(RoutingPathsEnum.documentSubmit);
	}

	captureEvent(event: string, label: string = undefined): void {
		this.tagDataService.link(
			{},
			{
				tealium_event: event,
				event_label: label
			}
		);
	}
}
