import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'op-plaid-benefits',
	templateUrl: './plaid-benefits.component.html',
	styleUrls: ['./plaid-benefits.component.scss']
})
export class PlaidBenefitsComponent implements OnInit {
	@Input()
	protected showIncentive: boolean;

	@Input()
	plaidConnectForIncomeVerified: boolean;

	protected forPage: string = '';

	constructor(private activatedRoute: ActivatedRoute) {}

	ngOnInit() {
		this.forPage = this.activatedRoute.snapshot.url.map((segment) => segment.path).join('/');
	}
}
