import { Component } from '@angular/core';

import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { AchBankAccountsService } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import {
	BankConnectResponseStatusEnum,
	IBankConnectEvent,
	PlaidLinkService
} from 'src/app/core/services/plaid-link/plaid-link.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { AddBankAccountComponent } from './add-bank-account.component';
import { ISetAchBankAccountDetail } from 'src/app/core/services/mobile-api';

@Component({
	selector: 'op-add-bank-account-funding-selection',
	templateUrl: './add-bank-account-funding-selection.component.html',
	styleUrls: ['./add-bank-account.component.scss']
})
export class AddBankAccountFundingSelectionComponent extends AddBankAccountComponent {
	titleKey: string = 'DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.debitCardFundingTitle';

	nextRoute = RoutingPathsEnum.addDebitCard;

	showBenefits: boolean = true;

	showPlaidTerms: boolean = false;

	showSiteTerms: boolean = false;

	showHorizontalLine: boolean = false;

	hasBankAccountError: boolean = false;

	constructor(
		protected routingService: RoutingService,
		protected dialogService: DialogService,
		protected bankAccountService: AchBankAccountsService,
		protected tagDataService: TagDataService,
		protected loanAppService: LoanApplicationService,
		protected plaidLinkService: PlaidLinkService,
		protected sessionStorageService: SessionStorageService
	) {
		super(
			routingService,
			dialogService,
			bankAccountService,
			tagDataService,
			loanAppService,
			plaidLinkService,
			sessionStorageService
		);
	}

	ngOnInit() {
		super.ngOnInit();
		this.showAddButton = true;
	}

	hideAccountList($event: boolean): void {
		this.showAddButton = true;
	}

	bankConnectionCompleteCallback(event: IBankConnectEvent): void {
		this.hasBankAccountError = event.data.responseStatus === BankConnectResponseStatusEnum.emptyAccountList;
		if (!this.hasBankAccountError) {
			this.routingService.route(this.nextRoute);
		}
	}

	bankErrorCallback(): void {
		this.hasBankAccountError = true;
	}

	bankDetailsSaved($event: ISetAchBankAccountDetail): void {
		if ($event?.verified) {
			this.routingService.route(this.nextRoute);
		}
	}
}
