import { Component, EventEmitter, Output } from '@angular/core';
import { RoutingService } from 'src/app/core/services/routing/routing.service';

@Component({
	selector: 'op-money-lion',
	templateUrl: './moneylion.component.html',
	styleUrls: ['./moneylion.component.scss']
})
export class MoneyLionComponent {
	@Output() OnClickTriggerAction = new EventEmitter<string>();

	constructor(private routingService: RoutingService) {}

	public goToMoneyLion(): void {
		this.OnClickTriggerAction.emit('moneyLion_offer_clicked');
	}
}
