import { Component, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import {
	combineLatest,
	filter,
	map,
	mergeMap,
	Observable,
	of,
	Subject,
	Subscription,
	switchMap,
	takeUntil
} from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import {
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';

import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ApplicationFlowEnum, IDebitCardDetails, MobileApiService } from 'src/app/core/services/mobile-api';

import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import {
	DisbursementChannelEnum,
	DisbursementType
} from 'src/app/core/services/loan-application/disbursement/disbursement.model';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';

import { CheckDeliveryConfirmComponent } from 'src/app/shared/components/dialogs/check-delivery-confirm/check-delivery-confirm.component';
import { ApplicationStatusEnum, ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';

import { MessageConfirmDialogComponent } from 'src/app/shared/components/dialogs/message-confirm-dialog/message-confirm-dialog.component';
import { FundingMethodConfirmComponent } from 'src/app/shared/components/dialogs/funding-method-confirm/funding-method-confirm.component';

@Component({
	selector: 'op-funding-options',
	templateUrl: './funding-options.component.html',
	styleUrls: ['./funding-options.component.scss']
})
export class FundingOptionsComponent implements OnInit, OnDestroy {
	private subscription = new Subscription();
	private unsubscribed = new Subject<void>();
	fundingOptionsEnum = DisbursementType;
	selectedFundingMethod: DisbursementType = null;
	selectedVerifiedAccountId: number;

	isPrequalApplication: boolean = false;

	eligibleForDebitCard: boolean = false;

	showDebitCardError: boolean = false;
	showFundingOptions: boolean = false;
	debitCardDetails: IDebitCardDetails = null;
	private groupedAccountList: Map<string, IAchBankAccount[]>;

	@ViewChild('debitCard') set controlElRef(debitCardElement: ElementRef) {
		this.tagDataService.link(
			{},
			{
				tealium_event: `debit card displayed ${debitCardElement != null}`,
				event_label: this.loanAppService.isGCPOrReturnApplicant() ? 'returning' : 'new',
				event_action: 'on_page_load'
			}
		);
	}

	constructor(
		private route: ActivatedRoute,
		private routingService: RoutingService,
		private tagDataService: TagDataService,
		private dialogService: DialogService,
		private translocoService: TranslocoService,
		private bankAccountService: AchBankAccountsService,
		private mobileApiService: MobileApiService,
		private loanAppService: LoanApplicationService
	) {}

	ngOnInit() {
		this.showDebitCardError = this.route.snapshot.queryParamMap?.get('error') === 'debitCard';

		const fundingSub = combineLatest([
			this.bankAccountService.bankAccounts$,
			this.loanAppService.isGCPOrReturnApplicant() ? this.bankAccountService.returningBankAccounts$ : of([])
		])
			.pipe(
				map(([rsp, returningRsp]) => rsp?.concat(returningRsp) || []),
				tap((bankAccounts) => {
					this.groupBankAccount(bankAccounts);
				}),
				mergeMap(() => {
					return this.loanAppService.loanApplication$;
				}),
				distinctUntilChanged(),
				tap((loanApp) => {
					this.selectFundingOption(loanApp);
				}),
				takeUntil(this.unsubscribed),
				mergeMap((loanApp) => {
					return this.mobileApiService.getDebitCardEligibility(loanApp.id, 0, {
						applicationType: loanApp.applicationType
					});
				}),
				tap((featureEligibility) => {
					this.eligibleForDebitCard = featureEligibility.eligible;
					this.showFundingOptions = true;
				}),
				mergeMap((featureEligibility) => {
					return featureEligibility.eligible
						? this.mobileApiService.getDebitCard(this.loanAppService.loanApplicationId)
						: of(null);
				})
			)
			.subscribe({
				next: (debitCardDetails) => {
					this.debitCardDetails = debitCardDetails;
				}
			});
		this.subscription.add(fundingSub);
	}

	ngOnDestroy(): void {
		this.stopSubscription();
		this.subscription.unsubscribe();
	}

	setSelectedPaymentMethod(paymentMethod: DisbursementType): void {
		this.selectedFundingMethod = paymentMethod;
	}

	continue(): void {
		const tagEvent = {
			tealium_event: 'button_click',
			event_label: this.selectedFundingMethod
		};
		this.tagDataService.link({}, tagEvent);

		switch (this.selectedFundingMethod) {
			case DisbursementType.ach:
				this.stopSubscription();
				this.mobileApiService.updateDisbursementAch(true, this.loanAppService.loanApplicationId).subscribe({
					next: () => {
						this.routingService.route(RoutingPathsEnum.addBankAccount);
					}
				});
				break;
			case DisbursementType.check:
				this.openDenyAchDisbursementDialog()
					.pipe(
						switchMap((result) => {
							this.stopSubscription();
							return result
								? this.mobileApiService.updateDisbursementAch(false, this.loanAppService.loanApplicationId)
								: of(null);
						})
					)
					.subscribe({
						next: (result) => {
							if (result) {
								this.routingService.route(RoutingPathsEnum.documentSubmit);
							}
						}
					});
				break;

			case DisbursementType.debit:
				if (this.debitCardDetails) {
					this.showDebitCardDisbursementConfirm()
						.pipe(filter((result) => Boolean(result)))
						.subscribe({
							next: () => {
								this.routingService.route(RoutingPathsEnum.documentSubmit);
							}
						});
				} else {
					this.showDebitCardDisbursementAuthorization()
						.pipe(filter((result) => !!result))
						.subscribe({
							next: (result) => {
								this.routingService.route(RoutingPathsEnum.addDebitCard);
							}
						});
				}

				break;
			default:
				break;
		}
	}

	get isFormValid(): boolean {
		return (
			this.selectedFundingMethod === DisbursementType.ach ||
			this.selectedFundingMethod === DisbursementType.check ||
			this.selectedFundingMethod === DisbursementType.debit
		);
	}

	accountSelected(bankId: number): void {
		this.selectedVerifiedAccountId = bankId;
	}

	onDebitCardSelection(): void {
		this.selectedFundingMethod = DisbursementType.debit;
	}

	onBankSelection(): void {
		this.selectedFundingMethod = DisbursementType.ach;
	}

	onCheckSelection(): void {
		this.selectedFundingMethod = DisbursementType.check;
	}

	private openDenyAchDisbursementDialog(): Observable<any> {
		if (OrganizationUtils.isMetaBank(this.loanAppService.getLoanApp().issuingOrganization)) {
			return this.dialogService.openSimpleDialog(CheckDeliveryConfirmComponent);
		} else {
			return of(true);
		}
	}

	private groupBankAccount(bankAccounts: IAchBankAccount[]) {
		if (this.groupedAccountList) {
			this.groupedAccountList = new Map();
		}
		this.groupedAccountList = bankAccounts.reduce(
			(entryMap, e) => entryMap.set(e.bankName, [...(entryMap.get(e.bankName) || []), e]),
			new Map()
		);
	}

	private selectFundingOption(loanApp: ILoanApplication): void {
		if (!this.showDebitCardError || loanApp?.disbursement?.disbursementType !== DisbursementType.debit) {
			this.selectedFundingMethod = loanApp?.disbursement?.disbursementType;
		}

		this.isPrequalApplication =
			loanApp?.applicationFlow === ApplicationFlowEnum.oportunPrequal &&
			loanApp?.applicationStatus === ApplicationStatusEnum.prequalAccepted;

		this.accountSelected(loanApp.disbursement.disbursementBankAccountId);
	}

	showDebitCardDisbursementConfirm() {
		return this.mobileApiService
			.updateDisbursementChannel(
				{
					disbursementChannel: DisbursementChannelEnum.online,
					disbursementType: DisbursementType.debit
				},
				this.loanAppService.loanApplicationId
			)
			.pipe(
				mergeMap((result) => {
					return this.dialogService
						.openSimpleDialog(FundingMethodConfirmComponent, {
							data: {
								fundingOptions: DisbursementType.debit,
								card: {
									last4: this.debitCardDetails.last4,
									fundsAvailability: this.debitCardDetails.fundsAvailability
								}
							}
						})
						.pipe(
							map((result) => {
								if (result) {
									this.stopSubscription();
								}
								return !!result;
							})
						);
				})
			);
	}

	showDebitCardDisbursementAuthorization(): Observable<boolean> {
		const data = {
			title: this.translocoService.translate('DISBURSEMENT_SELECTION.DEBIT_CARD.AUTHORIZATION.title'),
			message: this.translocoService.translate('DISBURSEMENT_SELECTION.DEBIT_CARD.AUTHORIZATION.message'),
			confirmText: this.translocoService.translate('GLOBAL.continue')
		};
		return this.dialogService.openSimpleDialog(MessageConfirmDialogComponent, { data }).pipe(
			map((result) => {
				return !!result;
			})
		);
	}

	private stopSubscription(): void {
		this.unsubscribed.next();
		this.unsubscribed.complete();
	}
}
