import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { iif, mergeMap, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { RoutingPathsEnum } from 'src/app/core/services/routing/routing.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { IFeatureEligibilityResult, MobileApiService } from 'src/app/core/services/mobile-api';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

import { ApplicationStatusEnum } from 'src/app/core/services/loan-application/loan-application.model';

@Injectable()
export class FundingOptionsRoutingGuard implements CanActivate {
	routingMappings = [
		{ from: RoutingPathsEnum.fundingOptions, to: RoutingPathsEnum.fundingSelection, expectedEligibleValue: true },
		{ from: RoutingPathsEnum.fundingSelection, to: RoutingPathsEnum.fundingOptions, expectedEligibleValue: false },
		{
			from: RoutingPathsEnum.fundingSelectionAddBankAccount,
			to: RoutingPathsEnum.fundingOptions,
			expectedEligibleValue: false
		}
	];

	constructor(
		private router: Router,
		private tagDataService: TagDataService,
		private activatedRoute: ActivatedRoute,
		private mobileApiService: MobileApiService,
		private loanAppService: LoanApplicationService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.loanAppService.loanApplication$.pipe(
			filter(Boolean),
			mergeMap((loanApp) => {
				return iif(
					() => Boolean(loanApp?.applicationStatus === ApplicationStatusEnum.preApproved),
					this.mobileApiService.getNewMemberDebitCardUnlockEligibility(loanApp.id),
					of({ value: false })
				);
			}),

			map((result: IFeatureEligibilityResult) => {
				const routingMapping = this.routingMappings.find((mapping) => mapping.from === route.routeConfig.path);
				if (routingMapping?.expectedEligibleValue === result?.eligible) {
					this.tagDataService.link(
						{},
						{
							tealium_event: `${route.routeConfig.path} redirected to ${routingMapping?.to}`
						}
					);
					if (routingMapping?.to) {
						this.router.navigate([routingMapping?.to], {
							queryParams: route.queryParams,
							queryParamsHandling: 'merge',
							relativeTo: this.activatedRoute,
							replaceUrl: true
						});
					}
					return false;
				} else {
					this.tagDataService.link(
						{},
						{
							tealium_event: `${route.routeConfig.path} allowed`
						}
					);
					return true;
				}
			})
		);
	}
}
