import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslocoModule } from '@ngneat/transloco';
import { LottieModule } from 'ngx-lottie';
import { NgxMaskModule } from 'ngx-mask';

import { SharedModule } from '../shared/shared.module';
import { AddBankAccountTermsConfirmedComponent } from './add-bank-account/add-bank-account-terms-confirmed.component';
import { AddBankAccountComponent } from './add-bank-account/add-bank-account.component';
import { AddManuallyTermsConfirmComponent } from './add-bank-account/add-manually/add-manually-terms-confirm.component';
import { AddBankAccountFundingSelectionComponent } from './add-bank-account/add-bank-account-funding-selection.component';
import { AddDebitCardComponent } from './add-debit-card/add-debit-card.component';
import { AdditionalContactComponent } from './additional-contact/additional-contact.component';
import { AdjustTermsComponent } from './adjust-terms/adjust-terms.component';
import { LoanDetailsListComponent } from './adjust-terms/loan-details-list/loan-details-list.component';
import { LoanDetailsComponent } from './adjust-terms/loan-details-list/loan-details/loan-details.component';
import { PaymentDateComponent } from './adjust-terms/payment-date/payment-date.component';
import { PaymentFrequencyComponent } from './adjust-terms/payment-frequency/payment-frequency.component';
import { SwitchOfferComponent } from './adjust-terms/switch-offer/switch-offer.component';
import { AlmostDoneComponent } from './almost-done/almost-done.component';
import { ApprovalRoutingModule } from './approval-routing.module';
import { AutoVerifyIncomeComponent } from './auto-verify-income/auto-verify-income.component';
import { BankVerificationComponent } from './bank-verification/bank-verification.component';
import { FundingAlternativeComponent } from './bank-verification/funding-alternative/funding-alternative.component';
import { ProofOfAddressComponent } from './document-submit/auto-verification/address/proof-of-address.component';
import { AutoVerificationComponent } from './document-submit/auto-verification/auto-verification.component';
import { AutoVerificationService } from './document-submit/auto-verification/auto-verification.service';
import { ProofOfBankAccountComponent } from './document-submit/auto-verification/bank-account/proof-of-bank-account.component';
import { BaseCategoryComponent } from './document-submit/auto-verification/base-category/base-category.component';
import { DocumentBadgeComponent } from './document-submit/auto-verification/document-badge/document-badge.component';
import { DocumentClassificationComponent } from './document-submit/auto-verification/document-classification/document-classification.component';
import { CollationSelectionComponent } from './document-submit/auto-verification/document-collation/collation-selection/collation-selection.component';
import { DocumentCollationComponent } from './document-submit/auto-verification/document-collation/document-collation.component';
import { DocumentNotificationComponent } from './document-submit/auto-verification/document-notification/document-notification.component';
import { DocumentReviewComponent } from './document-submit/auto-verification/document-review/document-review.component';
import { DocumentSectionComponent } from './document-submit/auto-verification/document-section/document-section.component';
import { DocumentStatusComponent } from './document-submit/auto-verification/document-status/document-status.component';
import { DocumentUploadComponent } from './document-submit/auto-verification/document-upload/document-upload.component';
import { DragDropDirective } from './document-submit/auto-verification/document-upload/drag-drop.directive';
import { UploadDisplayIdleComponent } from './document-submit/auto-verification/document-upload/upload-display-idle/upload-display-idle.component';
import { UploadDisplayPendingComponent } from './document-submit/auto-verification/document-upload/upload-display-pending/upload-display-pending.component';
import { UploadDisplayProcessingComponent } from './document-submit/auto-verification/document-upload/upload-display-processing/upload-display-processing.component';
import { DocumentVThumbnailsComponent } from './document-submit/auto-verification/document-v-thumbnail/document-thumbnails.component';
import { IdentificationOtherComponent } from './document-submit/auto-verification/identification/income-other/identification-other.component';
import { IssuingCountryPipe } from './document-submit/auto-verification/identification/issuing-country.pipe';
import { ProofOfIdentificationComponent } from './document-submit/auto-verification/identification/proof-of-identification.component';
import { BankCriteriaMetComponent } from './document-submit/auto-verification/income/bank-criteria-met/bank-criteria-met.component';
import { ProofOfIncomeComponent } from './document-submit/auto-verification/income/proof-of-income.component';
import { SelfieComponent } from './document-submit/auto-verification/selfie/selfie.component';
import { ConfirmRemoveDocumentDialogComponent } from './document-submit/confirm-remove-document-dialog/confirm-remove-document-dialog.component';
import { ConfirmRemoveDocument2DialogComponent } from './document-submit/confirm-remove-document2-dialog/confirm-remove-document2-dialog.component';
import { ConfirmSubmitDialogComponent } from './document-submit/confirm-submit-dialog/confirm-submit-dialog.component';
import { DocumentSubmitComponent } from './document-submit/document-submit.component';
import { VerifyIncomeGuard } from './document-submit/guards/verify-income.guard';
import { DocumentExpansionPanelComponent } from './document-submit/manual-verification/document-expansion-panel/document-expansion-panel.component';
import { ExpandSectionComponent } from './document-submit/manual-verification/expand-section/expand-section.component';
import { ManualVerificationComponent } from './document-submit/manual-verification/manual-verification.component';
import { SectionAddressComponent } from './document-submit/manual-verification/section-address/section-address.component';
import { SectionBankComponent } from './document-submit/manual-verification/section-bank/section-bank.component';
import { SectionDmvReqIdProofComponent } from './document-submit/manual-verification/section-dmv-req-id-proof/section-dmv-req-id-proof.component';
import { SectionIdentificationComponent } from './document-submit/manual-verification/section-identification/section-identification.component';
import { SectionIncomeComponent } from './document-submit/manual-verification/section-income/section-income.component';
import { SectionSelfiePanelComponent } from './document-submit/manual-verification/section-selfie-panel/section-selfie-panel.component';
import { SectionVehicleDriversLicenseComponent } from './document-submit/manual-verification/section-vehicle-drivers-license/section-vehicle-drivers-license.component';
import { SectionVehiclePhotosComponent } from './document-submit/manual-verification/section-vehicle-photos/section-vehicle-photos.component';
import { SectionVehicleRegistrationComponent } from './document-submit/manual-verification/section-vehicle-registration/section-vehicle-registration.component';
import { SplProductOfferInfoCardComponent } from './document-submit/manual-verification/spl-product-offer-info-card/spl-product-offer-info-card.component';
import { FundingMethodAccordionComponent } from './funding-method/funding-method-accordion/funding-method-accordion.component';
import { FundingMethodComponent } from './funding-method/funding-method.component';
import { FundingSelectionCardComponent } from './funding-selection/funding-selection-card/funding-selection-card.component';
import { FundingSelectionComponent } from './funding-selection/funding-selection.component';
import { FundingOptionsCardComponent } from './funding-options/funding-options-card/funding-options-card.component';
import { FundingOptionsComponent } from './funding-options/funding-options.component';

import { FundsDisbursementSelectionComponent } from './funds/funds-disbursement-selection/funds-disbursement-selection.component';
import { FundsComponent } from './funds/funds.component';
import { FundsCheckComponent } from './funds/fundsCheck/fundsCheck.component';
import { IncomeSelectionComponent } from './income-verification/income-selection/income-selection.component';
import { IncomeVerificationComponent } from './income-verification/income-verification.component';
import { ConfirmAchDisbursementComponent } from './receive-funds/confirm-ach-disbursement/confirm-ach-disbursement.component';
import { ReceiveFundsComponent } from './receive-funds/receive-funds.component';
import { StoreLessDisbursementInfoComponent } from './store-less-disbursement-info/store-less-disbursement-info.component';
import { ChangeDisbursementOptionComponent } from './terms-confirmed/change-disbursement-option/change-disbursement-option.component';
import { GoToStoreComponent } from './terms-confirmed/go-to-store/go-to-store.component';
import { NextStepsComponent } from './terms-confirmed/next-steps/next-steps.component';
import { SignOnlineComponent } from './terms-confirmed/sign-online/sign-online.component';
import { TermsConfirmedComponent } from './terms-confirmed/terms-confirmed.component';
import { VerifyIncomeComponent } from './verify-income/verify-income.component';

/**
 * Approval status components.
 *
 * @export
 * @class ApprovalModule
 */
@NgModule({
	declarations: [
		AddBankAccountComponent,
		AddBankAccountFundingSelectionComponent,
		AddBankAccountTermsConfirmedComponent,
		AddDebitCardComponent,
		AdditionalContactComponent,
		AddManuallyTermsConfirmComponent,
		AdjustTermsComponent,
		AlmostDoneComponent,
		AutoVerificationComponent,
		AutoVerifyIncomeComponent,
		BankCriteriaMetComponent,
		BankVerificationComponent,
		BaseCategoryComponent,
		ChangeDisbursementOptionComponent,
		CollationSelectionComponent,
		ConfirmAchDisbursementComponent,
		ConfirmRemoveDocument2DialogComponent,
		ConfirmRemoveDocumentDialogComponent,
		ConfirmSubmitDialogComponent,
		DocumentBadgeComponent,
		DocumentClassificationComponent,
		DocumentCollationComponent,
		DocumentExpansionPanelComponent,
		DocumentNotificationComponent,
		DocumentReviewComponent,
		DocumentSectionComponent,
		DocumentStatusComponent,
		DocumentSubmitComponent,
		DocumentUploadComponent,
		DocumentVThumbnailsComponent,
		DragDropDirective,
		ExpandSectionComponent,
		FundingAlternativeComponent,
		FundingMethodAccordionComponent,
		FundingMethodComponent,
		FundingOptionsCardComponent,
		FundingOptionsComponent,
		FundingSelectionCardComponent,
		FundingSelectionComponent,
		FundsCheckComponent,
		FundsComponent,
		FundsDisbursementSelectionComponent,
		GoToStoreComponent,
		IdentificationOtherComponent,
		IncomeSelectionComponent,
		IncomeVerificationComponent,
		IssuingCountryPipe,
		LoanDetailsComponent,
		LoanDetailsListComponent,
		ManualVerificationComponent,
		NextStepsComponent,
		PaymentDateComponent,
		PaymentFrequencyComponent,
		ProofOfAddressComponent,
		ProofOfBankAccountComponent,
		ProofOfIdentificationComponent,
		ProofOfIncomeComponent,
		ReceiveFundsComponent,
		SectionAddressComponent,
		SectionBankComponent,
		SectionDmvReqIdProofComponent,
		SectionIdentificationComponent,
		SectionIncomeComponent,
		SectionSelfiePanelComponent,
		SectionVehicleDriversLicenseComponent,
		SectionVehiclePhotosComponent,
		SectionVehicleRegistrationComponent,
		SelfieComponent,
		SignOnlineComponent,
		SplProductOfferInfoCardComponent,
		StoreLessDisbursementInfoComponent,
		SwitchOfferComponent,
		TermsConfirmedComponent,
		UploadDisplayIdleComponent,
		UploadDisplayPendingComponent,
		UploadDisplayProcessingComponent,
		VerifyIncomeComponent
	],
	providers: [VerifyIncomeGuard, IssuingCountryPipe, AutoVerificationService],
	imports: [
		CommonModule,
		SharedModule,
		TranslocoModule,
		ApprovalRoutingModule,
		NgxMaskModule.forChild(),
		LottieModule,
		MatTabsModule
	],
	exports: []
})
export class ApprovalModule {}
