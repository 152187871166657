<div class="disbursement-options" *transloco="let t">
	<op-masthead [title]="t('DISBURSEMENT_SELECTION.title')"></op-masthead>
	<div *ngIf="showDebitCardError">
		<div class="op-header-1-font">{{ t('DISBURSEMENT_SELECTION.DEBIT_CARD.NOT_SUPPORTED.title') }}</div>
		<div class="op-mtb-20 op-dark-grey">{{ t('DISBURSEMENT_SELECTION.DEBIT_CARD.NOT_SUPPORTED.message') }}</div>
		<div class="op-mb-20 op-dark-grey">{{ t('DISBURSEMENT_SELECTION.DEBIT_CARD.NOT_SUPPORTED.note') }}</div>
	</div>
	<op-content-container class="content" *ngIf="showFundingOptions">
		<op-funding-options-card
			#debitCard
			*ngIf="eligibleForDebitCard && !showDebitCardError"
			[id]="'debit_card'"
			[paymentMethodTitle]="t('DISBURSEMENT_SELECTION.DEBIT_CARD.title')"
			[paymentMethodInfo]="t('DISBURSEMENT_SELECTION.DEBIT_CARD.info')"
			[paymentMethodNote]="t('DISBURSEMENT_SELECTION.free')"
			[paymentMethodDuration]="t('DISBURSEMENT_SELECTION.DEBIT_CARD.duration')"
			[paymentMethodDurationInfo]="t('DISBURSEMENT_SELECTION.within30Mins')"
			paymentMethodIcon="op-atmCard"
			[debitCardDetails]="debitCardDetails"
			[selected]="selectedFundingMethod === fundingOptionsEnum.debit"
			(opened)="setSelectedPaymentMethod(fundingOptionsEnum.debit)"
			(click)="onDebitCardSelection()"
		>
		</op-funding-options-card>
		<op-funding-options-card
			[id]="'plaid-connect'"
			[paymentMethodTitle]="t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.title')"
			[paymentMethodInfo]="
				t(
					groupedAccountList?.size > 0
						? 'DISBURSEMENT_SELECTION.BANK_ACCOUNT.info'
						: 'DISBURSEMENT_SELECTION.BANK_ACCOUNT.infoNoBank'
				)
			"
			[paymentMethodNote]="t('DISBURSEMENT_SELECTION.free')"
			[paymentMethodDuration]="t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.duration')"
			[paymentMethodDurationInfo]="t('DISBURSEMENT_SELECTION.businessDays')"
			[groupedAccountList]="groupedAccountList"
			paymentMethodIcon="op-bank"
			[selected]="selectedFundingMethod === fundingOptionsEnum.ach"
			(opened)="setSelectedPaymentMethod(fundingOptionsEnum.ach)"
			(click)="onBankSelection()"
		>
		</op-funding-options-card>

		<op-funding-options-card
			[id]="'check'"
			[paymentMethodTitle]="t('DISBURSEMENT_SELECTION.CHECK.title')"
			[paymentMethodInfo]="t('DISBURSEMENT_SELECTION.CHECK.info')"
			[paymentMethodNote]="t('DISBURSEMENT_SELECTION.free')"
			[paymentMethodDuration]="t('DISBURSEMENT_SELECTION.CHECK.duration')"
			[paymentMethodDurationInfo]="t('DISBURSEMENT_SELECTION.businessDays')"
			paymentMethodIcon="op-paperCheck"
			[selected]="selectedFundingMethod === fundingOptionsEnum.check"
			(click)="onCheckSelection()"
			(opened)="setSelectedPaymentMethod(fundingOptionsEnum.check)"
		>
		</op-funding-options-card>

		<div class="op-mb-30 op-mt-8 op-gray op-micro-font" *ngIf="eligibleForDebitCard && !showDebitCardError">
			{{ t('DISBURSEMENT_SELECTION.expectDelayNotice') }}
		</div>

		<div class="op-center">
			<button
				mat-flat-button
				mat-primary
				class="op-full-width"
				[disabled]="!isFormValid"
				color="primary"
				id="continue"
				(click)="continue()"
			>
				{{ t('GLOBAL.continue') }}
			</button>
		</div>
	</op-content-container>
</div>
