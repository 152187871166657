<div class="add-bank-account" *transloco="let t">
	<op-back-link class="op-mt-20" [green]="false" id="addBankAccountBack">{{ t('ACCESSIBILITY.back') }}</op-back-link>
	<div *ngIf="accountList.length === 0 || showAddButton">
		<op-masthead [customClass]="'head'" [title]="t(titleKey)"></op-masthead>
		<op-content-container class="content">
			<op-plaid-button-ext
				id="plaidButton"
				[bankOnly]="true"
				[showBenefits]="showBenefits"
				[showPlaidTerms]="showPlaidTerms"
				[showSiteTerms]="showSiteTerms"
				[showIncentive]="showIncentive"
				[showHorizontalLine]="showHorizontalLine"
			></op-plaid-button-ext>
			<div class="add-bank-container" (click)="addManually()">
				<div class="add-text op-ml-10">
					<div class="title">
						<span>{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.manually') }}</span>
						<div class="arrow">
							<mat-icon svgIcon="op-chevron-right"></mat-icon>
						</div>
					</div>
				</div>
				<div class="description op-label-font op-ml-10 op-mt-4">
					{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.MANUALLY.enterRoutingNumber') }}
				</div>
			</div>
		</op-content-container>
	</div>
	<div *ngIf="accountList.length > 0 && !showAddButton">
		<op-masthead
			[customClass]="'head-bp-4'"
			[title]="t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.CONNECTED.title')"
		></op-masthead>
		<div class="op-gray op-body-1-font">{{ t('DISBURSEMENT_SELECTION.BANK_ACCOUNT.ADD.message') }}</div>
		<op-add-bank-ext
			(addAnotherBankAccount)="hideAccountList($event)"
			(bankSelected)="onBankSelected($event)"
		></op-add-bank-ext>
	</div>
</div>
