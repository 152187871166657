import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { camelCase, isEmpty } from 'lodash';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics/google-analytics.service';
import { ApplicantUtils } from 'src/app/core/services/loan-application/applicant/applicant-utils';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { FTRSectionNameEnum, FTRSectionRoute } from 'src/app/pre-approval/fast-track/fast-track-step.model';
import { REFERRAL_PARTNER } from 'src/app/shared/constants/common-const';

import { AchBankAccountsService } from '../ach-bank-accounts/ach-bank-accounts.service';
import { ApplicantStepCompleteEnum } from '../loan-application/applicant/applicant.model';
import { DisbursementUtils } from '../loan-application/disbursement/disbursement-utils';
import {
	ApplicationStatusEnum,
	ILoanApplication,
	ValidApplicationStateForOfferStatus
} from '../loan-application/loan-application.model';
import { LoanApplicationService } from '../loan-application/loan-application.service';
import { ProductOfferDetailsUtils } from '../loan-application/product-offer/product-offer-details/product-offer-details-utils';
import IProduct, {
	ProductCategoriesEnum,
	ProductSubStatusesEnum
} from '../loan-application/product-offer/product/product.model';
import { LoggingService } from '../logging/logging.service';
import { ApplicationFlowEnum } from '../mobile-api';
import { ILoanTermsResult } from '../mobile-api/mobile-api.model';
import { OnlineNotificationService } from '../online-notification/online-notification.service';
import { SessionStorageService, STORAGE_KEYS } from '../storage/session-storage.service';
import { TagDataService } from '../tag-data/tag-data.service';
import { DisbursementChannelEnum } from '../loan-application/disbursement/disbursement.model';
import { RoutingHistoryService } from './routing-history.service';

export interface IRoutingInfo {
	route: RoutingPathsEnum;
	extra?: NavigationExtras;
}

export enum RoutingPathsEnum {
	samples = 'samples',
	// Landing routes
	'addBankAccount' = 'add-bank-account',
	'addManually' = 'add-manually',
	'addDebitCard' = 'add-debit-card',
	'accessibility' = 'accessibility',
	'findYourLetter' = 'find-your-letter',
	'findYourApplication' = 'find-your-application',
	'findPrequalApplication' = 'find-prequal-application',
	'getOffer' = 'special-offer/get-offer',
	'home' = 'home',
	'howItWorks' = 'how-it-works',
	'loanDisclosures' = 'loan-disclosures',
	'multifactor' = 'multifactor',
	'offerAvailable' = 'special-offer/offer-available',
	'personalInfo' = 'personal-info',
	'preQualified' = 'pre-qualified',
	'specialOffer' = 'special-offer',
	'specialOffer1' = 'special-offer-1',
	'specialOffer2' = 'special-offer-2',
	'terms' = 'terms',
	'handlePrescreenApplication' = 'handle-prescreen-application',
	'bankConnect' = 'bank-connect',
	// pre-approved routes
	'address' = 'address',
	'plaidRefresh' = 'plaid-refresh',
	'plaidConnect' = 'plaid-connect',
	'creditAuth' = 'credit-auth',
	'debt' = 'debt',
	'offerStatusInformation' = 'offer-status-information',
	'finances' = 'finances',
	'identification' = 'identification',
	'income' = 'income',
	'loanPurpose' = 'loan-purpose',
	'references' = 'references',
	'securedPersonal' = 'secured-personal',
	'summary' = 'summary',
	'vehicle' = 'vehicle',
	'vehicleEligibility' = 'vehicle-eligibility',
	'identityConfirm' = 'identity-confirm',
	'spousalContact' = 'spousal-contact',
	'fastTrack' = 'fast-track',
	// approved routes
	'approved' = 'approved',
	'additionalContact' = 'additional-contact',
	'adjustTerms' = 'adjust-terms',
	'documentSubmit' = 'document-submit',
	'documentSubmitProofOfIncome' = 'document-submit/proof-of-income',
	'documentSubmitProofOfIdentification' = 'document-submit/proof-of-identification',
	'documentSubmitProofOfAddress' = 'document-submit/proof-of-address',
	'documentSubmitProofOfBankAccount' = 'document-submit/proof-of-bank-account',
	'documentSubmitProofOfSelfie' = 'document-submit/proof-of-selfie',
	'documentSubmitCollation' = 'document-submit/collation',
	'receiveFunds' = 'receive-funds',
	'fundingOptions' = 'funding-options',
	'fundingSelection' = 'funding-selection',
	'fundingSelectionAddBankAccount' = 'funding-selection/add-bank-account',
	'termsConfirmed' = 'terms-confirmed',
	'termsConfirmedAddBankAccount' = 'terms-confirmed/add-bank-account',
	'termsConfirmedAddManually' = 'terms-confirmed/add-manually',
	'autoVerifyIncome' = 'auto-verify-income',
	'verifyIncome' = 'verify-income',
	'incomeVerification' = 'income-verification',
	'bankVerification' = 'bank-verification',
	'funds' = 'funds',
	// disbursed routes
	'createAccount' = 'create-account',
	'loanCompleted' = 'loan-completed',
	'referral' = 'referral',
	// status routes
	'offerStatus' = 'offer-status',
	'status' = 'status',
	'loanBenefits' = 'loan-benefits',
	// terms routes
	'esignAutoPay' = 'esign-auto-pay',
	'esignAutoPayAddBank' = 'esign-auto-pay/add-bank',
	'manuallyAddBankAccount' = 'manually-add-bank-account',
	'esignConfirmTerm' = 'esign-confirm-term',
	// esign routes
	'esignRedirectLandingPage' = 'esign-redirect-landing-page',
	'esignEventSigningCanceled' = 'esign-event-signing-canceled',
	'esignEventSigningDeclined' = 'esign-event-signing-declined',
	'esignEventSigningFailed' = 'esign-event-signing-failed',
	'esignEventSigningTimeout' = 'esign-event-signing-timeout',
	'esignEventSigningCompleted' = 'esign-event-signing-completed',
	'esignEventViewingCompleted' = 'esign-event-viewing-completed',
	'esignHowToPrintSaveLoan' = 'esign-howto-print-save-loan',
	'esignSignDocument' = 'esign-sign-document',
	'esignPage' = 'esign/:page',
	'esignPageEvent' = 'esign/:page/:event',
	'esignPingListener' = 'esign-ping-listener',
	'esignShowError' = 'esign-show-error',
	'vendorGeneralInfo' = 'vendor-general-info',
	'vendorPersonalInfo' = 'vendor-personal-info',
	'landingForwardSlash' = 'landing/',
	'landing' = 'landing',
	'almostDone' = 'almost-done',
	'sso' = 'sso',
	'token' = 'token',
	'storeLessDisbursementInfo' = 'store-less-disbursement',
	'caFindPartnerDisclosure' = 'ca-finder-partner-disclosure',
	'esignDisclosures' = 'esign-disclosures',
	'acceptEsign' = 'accept-esign',
	'preQualify' = 'prequal',
	'additionalInformation' = 'additional-information',
	'preQualifyIdentification' = 'prequal/identification',
	'preQualifyEntry' = 'prequal/:token/:loanId',
	'preQualifyAddress' = 'prequal/address',
	'preQualifyDebt' = 'prequal/debt',
	'preQualifyIncome' = 'prequal/income',
	'preQualifyAdditionalInformation' = 'prequal/additional-information',
	'vehicleEligibilityQuestion' = 'vehicle-eligibility-question',
	'agentStore' = 'agent-store'
}

/**
 * Find the proper route based on the loan application status.
 *
 * @export
 * @class RoutingService
 */
@Injectable({
	providedIn: 'root'
})
export class RoutingService implements OnDestroy {
	private subscription = new Subscription();
	private reloaded = false;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private loanAppService: LoanApplicationService,
		private onlineNotificationService: OnlineNotificationService,
		private mobileService: MobileApiService,
		private sessionStorageService: SessionStorageService,
		private gaService: GoogleAnalyticsService,
		private tagDataService: TagDataService,
		private location: Location,
		private loggingService: LoggingService,
		private bankAccountService: AchBankAccountsService,
		private routingHistoryService: RoutingHistoryService
	) {
		const routingSub = this.router.events.pipe(filter((evt: any) => evt instanceof NavigationEnd)).subscribe({
			next: (event) => {
				this.reloaded = event.id === 1 && event.url === event.urlAfterRedirects;

				const url = new URL(event.urlAfterRedirects, window.location.origin).pathname;
				const previousPage = this.routingHistoryService.getCurrentRoute();
				const currentPage = RoutingPathsEnum[camelCase(url.substring(1))];

				if (previousPage !== currentPage) {
					this.routingHistoryService.addRoute(currentPage);
				}

				this.gaService.ga('set', 'page', event.urlAfterRedirects);
				this.gaService.ga('send', 'pageview');
				this.tagDataService.view(
					{},
					{
						tealium_event: 'page_view',
						reloaded: this.reloaded,
						page_location: event.urlAfterRedirects,
						event_label: currentPage
					}
				);
			}
		});
		this.subscription.add(routingSub);

		const routingSubForNavigationStart = this.router.events
			.pipe(filter((evt: any) => evt instanceof NavigationStart))
			.subscribe({
				next: (event) => {
					// event.restoredState will have a value/defined if the user press back/forward button on the browser
					if (event.restoredState && this.isInValidLoanState()) {
						this.route(RoutingPathsEnum.offerStatus);
					}
				}
			});
		this.subscription.add(routingSubForNavigationStart);
	}

	isInValidLoanState(): boolean {
		const productOfferDetailUtilities: ProductOfferDetailsUtils = ProductOfferDetailsUtils.fromLoanApp(
			this.loanAppService.getLoanApp()
		);
		const applicationStatus = this.loanAppService.applicationStatus;
		const validDualOffer = productOfferDetailUtilities.hasMultipleValidProductOffers();
		const isSplOnlyOffer = productOfferDetailUtilities.hasOnlySPL();
		const splOfferWithValidStatus = productOfferDetailUtilities.hasValidSPLOfferWithVehicle();

		return (
			(validDualOffer && this.isApplicationValidOfferStatus(applicationStatus)) ||
			(isSplOnlyOffer && splOfferWithValidStatus)
		);
	}

	isApplicationValidOfferStatus(applicationStatus: ApplicationStatusEnum) {
		return ValidApplicationStateForOfferStatus.includes(applicationStatus);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private isRoute(path: RoutingPathsEnum | Array<RoutingPathsEnum>, route: RoutingPathsEnum): boolean {
		const paths = Array.isArray(path) ? path : [path];
		return paths.some((r) => r === route);
	}

	isCurrentRoute(path: RoutingPathsEnum | Array<RoutingPathsEnum>): boolean {
		return this.isRoute(path, this.routingHistoryService.getCurrentRoute());
	}

	isPreviousRoute(path: RoutingPathsEnum | Array<RoutingPathsEnum>): boolean {
		return this.isRoute(path, this.routingHistoryService.getPreviousRoute());
	}

	/**
	 * determines if the page was reload/refreshed.
	 *
	 * @return {*}  {boolean}
	 * @memberof RoutingService
	 */
	isReloaded(): boolean {
		return this.reloaded;
	}

	/**
	 * Use local history to go back to avoid reloading.
	 *
	 * @memberof RoutingService
	 */
	back(extra: NavigationExtras = null): void {
		if (this.routingHistoryService.getLength() > 1) {
			this.routingHistoryService.removeRoute();
			this.route(this.routingHistoryService.getCurrentRoute(), extra);
		} else {
			this.location.back();
		}
	}

	/**
	 * Route the next page.
	 *	if route is null then get the route from the loanApp status.
	 *
	 * @param {RoutingPathsEnum}
	 * @param {*} [extra=null]
	 * @memberof RoutingService
	 */
	route(route: RoutingPathsEnum, extra: NavigationExtras = null): void {
		this.router.navigate([route], extra ? extra : undefined);
	}

	/**
	 * Gets the latest loanApp from Backend then routes to the next pages based
	 * on the loanApp status.
	 *
	 * @param {*} [extra=null]
	 * @memberof RoutingService
	 */
	routeFromLoanApp(extra: NavigationExtras = null): void {
		if (this.loanAppService.loanApplicationId) {
			this.loanAppService.updateLoanApplication().subscribe({
				next: (loanApp) => {
					const route = this.getRouteFromLoanApp(loanApp);
					this.route(route, this.transformExtra(route, extra));
				}
			});
		} else {
			this.loggingService.error('no loan app id');
			this.route(RoutingPathsEnum.findYourApplication);
		}
	}

	/**
	 * Gets the latest loanApp from Backend then routes to the next pages based
	 * on the loanApp status.
	 *
	 * @param {*} [extra=null]
	 * @memberof RoutingService
	 */
	routeFromLoanAppSub(extra: NavigationExtras = null): Observable<ILoanApplication> {
		if (this.loanAppService.loanApplicationId) {
			return this.loanAppService.updateLoanApplication().pipe(
				tap((loanApp) => {
					const route = this.getRouteFromLoanApp(loanApp);
					this.route(route, this.transformExtra(route, extra));
				})
			);
		} else {
			this.loggingService.error('no loan app id');
			this.route(RoutingPathsEnum.findYourApplication);
			return of(this.loanAppService.getLoanApp());
		}
	}

	/**
	 * When routing to the summary page, transform the queryParams returnToSummary to a fragment.
	 *
	 * @private
	 * @param {RoutingPathsEnum} route
	 * @param {*} [extra=null]
	 * @return {*}  {*}
	 * @memberof RoutingService
	 */
	private transformExtra(route: RoutingPathsEnum, extra: NavigationExtras = null): NavigationExtras {
		if (route === RoutingPathsEnum.summary && extra?.queryParams?.returnToSummary) {
			return { fragment: extra.queryParams.returnToSummary };
		} else {
			return extra;
		}
	}

	/**
	 * Set productCategorySelection as SPL if the subStatus is DMV_COMPLETE
	 * If the user is coming from find_you_application page, this values tends to be null
	 * and it will break the existing functionality
	 * @param productOfferDetailUtils
	 */
	setProductCategoryIfSubstatusIsComplete(productOfferDetailUtils: ProductOfferDetailsUtils): void {
		let completeSplProductSubStatus = productOfferDetailUtils.findProductSubStatus(
			ProductCategoriesEnum.securedPersonalLoan,
			ProductSubStatusesEnum.complete
		);
		if (completeSplProductSubStatus) {
			this.sessionStorageService.set('productCategorySelection', ProductCategoriesEnum.securedPersonalLoan);
		}
	}

	routeByStatus(extra: NavigationExtras = null): void {
		this.loanAppService.updateLoanApplication().subscribe({
			next: (loanApp) => {
				let resultRoute: RoutingPathsEnum;
				const productOfferDetailUtils = ProductOfferDetailsUtils.fromLoanApp(loanApp);
				const applicant = ApplicantUtils.fromLoanApp(this.loanAppService.getLoanApp());
				const plaidOneClickEligible = applicant.isPlaidOneClickEligible();

				if (
					loanApp.applicationFlow === ApplicationFlowEnum.oportunPrequal &&
					loanApp.applicationStatus === ApplicationStatusEnum.prequalAccepted
				) {
					resultRoute = this.getRouteStatusPrequalAccepted(loanApp);
				} else {
					// forward to the nextRoute from the sessionStorage and clear it
					const routeFromSessionStorage = this.sessionStorageService.get('nextRoute');
					if (routeFromSessionStorage) {
						this.sessionStorageService.set('nextRoute', '');
						resultRoute = RoutingPathsEnum[routeFromSessionStorage];
					} else if (
						!productOfferDetailUtils.isAllLoansHaveValidStatus() &&
						this.canViewVehicleEligibility(loanApp, productOfferDetailUtils.getSecuredPersonalLoanProduct())
					) {
						// this use-case covers when
						// there is only a SPL and user gave a ineligible vehicle
						resultRoute = RoutingPathsEnum.vehicleEligibility;
					} else if (
						this.routingHistoryService.getCurrentRoute() === RoutingPathsEnum.multifactor &&
						plaidOneClickEligible
					) {
						resultRoute = RoutingPathsEnum.plaidConnect;
					} else if (this.isStorelessRedirectEligible(productOfferDetailUtils, loanApp)) {
						resultRoute = RoutingPathsEnum.storeLessDisbursementInfo;
					} else if (this.loanAppService.isApplicationStatusBounced() || applicant.isCoApplicant()) {
						this.setProductCategoryInSession(loanApp);
						resultRoute = RoutingPathsEnum.documentSubmit;
					} else if (productOfferDetailUtils.hasAtleastOneLoan() && this.shouldLandOnOfferStatus(loanApp)) {
						resultRoute = RoutingPathsEnum.offerStatus;
					} else if (ApplicationStatusEnum.approved === loanApp.applicationStatus) {
						resultRoute = RoutingPathsEnum.adjustTerms;
					} else if (this.shouldLandOnEsignAutoPay(loanApp)) {
						resultRoute = RoutingPathsEnum.esignAutoPay;
					} else if (ApplicationStatusEnum.termsConfirmed === loanApp?.applicationStatus) {
						this.setProductCategoryInSession(loanApp);
						return this.routeToOnlineNotificationRoute(loanApp).subscribe();
					} else if (
						ApplicationStatusEnum.preApproved === loanApp?.applicationStatus &&
						this.loanAppService.isIncomeVerificationEligible()
					) {
						resultRoute = this.loanAppService.isBtmEligible()
							? this.getBtmRoute()
							: RoutingPathsEnum.incomeVerification;
					} else {
						resultRoute = this.getRouteFromLoanApp(loanApp);
					}
				}
				this.route(resultRoute, extra);
			}
		});
	}

	private canViewVehicleEligibility(loanApp: ILoanApplication, validSplObject: IProduct): boolean {
		const appStatus = loanApp?.applicationStatus;
		return (
			appStatus === ApplicationStatusEnum.securedOffered ||
			(appStatus === ApplicationStatusEnum.securedAccepted && !validSplObject)
		);
	}

	private shouldLandOnOfferStatus(loanApp: ILoanApplication): boolean {
		let productCategoryInSession = this.sessionStorageService.get('productCategorySelection');
		const offerStatusList = [
			ApplicationStatusEnum.preApproved,
			ApplicationStatusEnum.approved,
			ApplicationStatusEnum.securedOffered,
			ApplicationStatusEnum.securedAccepted,
			ApplicationStatusEnum.securedDeclined
		];
		return isEmpty(productCategoryInSession) && offerStatusList.includes(loanApp.applicationStatus);
	}

	private shouldLandOnEsignAutoPay(loanApp: ILoanApplication): boolean {
		let rachWithoutAchOptInEligible = loanApp?.rachWithoutAchOptInEligible;
		const appStatus = [ApplicationStatusEnum.termsConfirmed, ApplicationStatusEnum.documentsSigned];
		return rachWithoutAchOptInEligible && appStatus.includes(loanApp.applicationStatus);
	}

	private setProductCategoryInSession(loanApp: ILoanApplication): void {
		const productCategoryInSession = this.sessionStorageService.get('productCategorySelection');
		if (!productCategoryInSession && isEmpty(productCategoryInSession)) {
			this.sessionStorageService.set('productCategorySelection', loanApp?.productCategory);
		}
	}

	isStorelessRedirectEligible(productOfferDetailUtils: ProductOfferDetailsUtils, loanApp: ILoanApplication): boolean {
		if (this.loanAppService.isApplicationStatusApproved()) {
			const dmvProcessInitiated = productOfferDetailUtils.findProductSubStatus(
				ProductCategoriesEnum.securedPersonalLoan,
				ProductSubStatusesEnum.initiated
			);
			const nullProductSubstatus = productOfferDetailUtils.findProductSubStatus(
				ProductCategoriesEnum.securedPersonalLoan,
				null
			);

			let isSplProductCategory =
				this.sessionStorageService.get('productCategorySelection') === ProductCategoriesEnum.securedPersonalLoan ||
				loanApp.productCategory === ProductCategoriesEnum.securedPersonalLoan;
			if (
				this.loanAppService.getLoanApp().isSplStorelessEligible &&
				(dmvProcessInitiated || (isSplProductCategory && nullProductSubstatus))
			) {
				return true;
			}
		}
		return false;
	}

	isSplStorelessEligibleWithStatus(status: ProductSubStatusesEnum): boolean {
		let loanApp: ILoanApplication = this.loanAppService.getLoanApp();
		const productOfferDetailUtils = ProductOfferDetailsUtils.fromLoanApp(loanApp);

		// add productCategory
		//
		const selectedProductCategory: ProductCategoriesEnum = this.sessionStorageService.get('productCategorySelection');
		let isSplSelected = selectedProductCategory === ProductCategoriesEnum.securedPersonalLoan;
		if (status === ProductSubStatusesEnum.complete && !selectedProductCategory) {
			this.setProductCategoryIfSubstatusIsComplete(productOfferDetailUtils);
			isSplSelected = true;
		}

		let dmvCompleteSplExists = Boolean(
			productOfferDetailUtils.findProductSubStatus(ProductCategoriesEnum.securedPersonalLoan, status)
		);

		return loanApp.isSplStorelessEligible && dmvCompleteSplExists && isSplSelected;
	}

	private getRouteStatusStarted(loanApp: ILoanApplication): RoutingPathsEnum {
		const plaidBtmEligible = ApplicantUtils.fromLoanApp(loanApp).isBtmEligible();
		const bankAdded = this.loanAppService.isBtmBankConnected();
		const plaidRefreshEligible = Boolean(this.sessionStorageService.get('plaidRefreshEligible'));
		const plaidConnectAddressScreenOrderSwapEnabled = Boolean(
			this.sessionStorageService.get(STORAGE_KEYS.PLAID_CONNECT_ADDRESS_SCREEN_ORDER_SWAP_ENABLED)
		);
		const zeusConnectLinkEnabled = Boolean(this.sessionStorageService.get(STORAGE_KEYS.ZEUS_CONNECT_LINK_ENABLED));

		// Added tealium log to debug zeus plaid connect issue. Will be removed once issue is identified
		this.tagDataService.view(
			{},
			{
				tealium_event: 'zeus_connect_link_Event',
				zeusConnectLinkEnabled: zeusConnectLinkEnabled,
				nameStep: this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.name),
				phoneStep: this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.phone),
				identificationStep: this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.identification),
				creditAuthStep: this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.creditAuth) || false,
				fromMultifactorRoute: this.isCurrentRoute([RoutingPathsEnum.multifactor]),
				originationSource: loanApp.originationSource
			}
		);

		if (plaidRefreshEligible && this.isCurrentRoute([RoutingPathsEnum.loanPurpose])) {
			return RoutingPathsEnum.plaidRefresh;
		}

		if (
			zeusConnectLinkEnabled &&
			this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.name) &&
			this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.phone) &&
			this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.identification) &&
			this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.address) &&
			!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.creditAuth) &&
			this.isCurrentRoute([RoutingPathsEnum.multifactor]) &&
			loanApp.originationSource !== DisbursementChannelEnum.online
		) {
			return RoutingPathsEnum.plaidConnect;
		}

		if (plaidBtmEligible && !bankAdded) {
			const currentRoute = plaidConnectAddressScreenOrderSwapEnabled
				? RoutingPathsEnum.address
				: RoutingPathsEnum.loanPurpose;
			if (this.isCurrentRoute([currentRoute])) {
				return RoutingPathsEnum.plaidConnect;
			}
		}

		if (!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.name)) {
			return RoutingPathsEnum.personalInfo;
		}

		if (!loanApp.loanPurpose) {
			return RoutingPathsEnum.loanPurpose;
		}

		if (!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.address)) {
			if (
				plaidConnectAddressScreenOrderSwapEnabled &&
				plaidBtmEligible &&
				!bankAdded &&
				this.isCurrentRoute([RoutingPathsEnum.multifactor])
			) {
				return RoutingPathsEnum.plaidConnect;
			} else {
				return RoutingPathsEnum.address;
			}
		} else if (!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.phone)) {
			return RoutingPathsEnum.personalInfo;
		} else if (
			!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.identification) &&
			this.loanAppService.isCreditRunSuccess() === false
		) {
			return RoutingPathsEnum.identityConfirm;
		} else if (!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.identification)) {
			if (
				!plaidConnectAddressScreenOrderSwapEnabled &&
				plaidBtmEligible &&
				!bankAdded &&
				this.isCurrentRoute([RoutingPathsEnum.multifactor])
			) {
				return RoutingPathsEnum.plaidConnect;
			} else {
				return RoutingPathsEnum.identification;
			}
		} else if (!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.creditAuth)) {
			return RoutingPathsEnum.creditAuth;
		} else if (!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.finance)) {
			return RoutingPathsEnum.finances;
		} else if (!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.income)) {
			return RoutingPathsEnum.income;
		} else if (!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.preApproval, true)) {
			return RoutingPathsEnum.summary;
		} else {
			return RoutingPathsEnum.status;
		}
	}

	private getRouteStatusPrequalAccepted(loanApp: ILoanApplication): RoutingPathsEnum {
		if (!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.address)) {
			return RoutingPathsEnum.preQualifyAddress;
		} else if (!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.identification)) {
			return RoutingPathsEnum.preQualifyIdentification;
		} else if (!loanApp.loanPurpose) {
			return RoutingPathsEnum.preQualifyAdditionalInformation;
		} else if (!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.income)) {
			return RoutingPathsEnum.preQualifyIncome;
		}
		//Default page
		return RoutingPathsEnum.preQualifyAddress;
	}

	public routeToOnlineNotificationRoute(loanApp: ILoanApplication): Observable<RoutingPathsEnum> {
		let loanTermsResult = { termsExpired: false };
		const productCategorySelection = this.sessionStorageService.get('productCategorySelection');
		return this.mobileService.getLoanTerms(productCategorySelection, loanApp.id).pipe(
			switchMap((loanTerms: ILoanTermsResult) => {
				loanTermsResult = loanTerms;
				return this.determineOnlineNotificationRoute(loanApp, loanTerms);
			}),
			map((rsp) => {
				const nextRoute = rsp ? rsp : RoutingPathsEnum.status;
				this.route(nextRoute);
				return nextRoute;
			}),
			catchError((err) => {
				const disbursement = DisbursementUtils.fromLoanApp(loanApp);
				if (
					this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.notification, true) &&
					disbursement.isChannelOnline()
				) {
					return this.routeToConfirmTerms(loanApp, loanTermsResult as ILoanTermsResult);
				} else {
					this.route(RoutingPathsEnum.status);
					return of(RoutingPathsEnum.status);
				}
			})
		);
	}

	private determineOnlineNotificationRoute(
		loanApp: ILoanApplication,
		loanTerms: ILoanTermsResult
	): Observable<RoutingPathsEnum> {
		return this.onlineNotificationService.getOnlineNotificationOptions().pipe(
			take(1),
			switchMap((rsp) => {
				if (rsp?.onlineNotifyEnabled || rsp?.onlineNotifyNewAppEnabled) {
					return this.getOnlineNotificationStep(loanApp, loanTerms);
				} else {
					of(RoutingPathsEnum.status);
				}
			})
		);
	}

	public routeBtm(): void {
		this.loanAppService.updateLoanApplication().subscribe({
			next: () => {
				const route = this.getBtmRoute();
				this.route(route);
			}
		});
	}

	private getBtmRouteFromExternal(): RoutingPathsEnum {
		// income is partial verified
		if (this.loanAppService.isBtmIncomePartialVerified()) {
			return RoutingPathsEnum.incomeVerification;
		}

		// income is verified
		if (this.loanAppService.isBtmIncomeVerified()) {
			if (
				!this.bankAccountService.isBankVerified() &&
				!this.bankAccountService.isReturningBankVerified() &&
				!this.loanAppService.isBtmBankConnected()
			) {
				return RoutingPathsEnum.bankVerification;
			} else {
				return RoutingPathsEnum.funds;
			}
		}

		return RoutingPathsEnum.incomeVerification;
	}

	private getBtmRoute(): RoutingPathsEnum {
		// From dualOffer or vehicleEligibility page
		if (
			this.isCurrentRoute([
				RoutingPathsEnum.multifactor,
				RoutingPathsEnum.offerStatus,
				RoutingPathsEnum.vehicleEligibility,
				RoutingPathsEnum.preQualifyIncome
			])
		) {
			return this.getBtmRouteFromExternal();
		}

		// From income verification page
		if (this.isCurrentRoute(RoutingPathsEnum.incomeVerification)) {
			// NOT plaid connected and NOT bank verified and NOT income verified and NOT income partial verified
			if (
				!this.loanAppService.isBtmBankConnected() &&
				!this.bankAccountService.isBankVerified() &&
				!this.bankAccountService.isReturningBankVerified() &&
				!this.loanAppService.isBtmIncomeVerified() &&
				!this.loanAppService.isBtmIncomePartialVerified()
			) {
				return RoutingPathsEnum.bankVerification;
			} else {
				return RoutingPathsEnum.funds;
			}
		}

		// from bank verification page
		if (this.isCurrentRoute(RoutingPathsEnum.bankVerification)) {
			return RoutingPathsEnum.funds;
		}

		// from funds pages
		if (this.isCurrentRoute(RoutingPathsEnum.funds)) {
			return RoutingPathsEnum.documentSubmit;
		}

		return RoutingPathsEnum.incomeVerification;
	}

	private getRouteStatusPreApproved(loanApp: ILoanApplication): RoutingPathsEnum {
		const currentApplicant = this.loanAppService.getCurrentApplicant();

		if (
			!this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.reference) &&
			currentApplicant.referencesRequired > 0
		) {
			return RoutingPathsEnum.references;
		} else {
			return RoutingPathsEnum.receiveFunds;
		}
	}

	/**
	 * Get the next route the user needs to complete
	 *
	 * @param {ILoanApplication}
	 * @return {*}  {IPagesEnum}
	 * @memberof RoutingService
	 */
	private getRouteFromLoanApp(loanApplication: ILoanApplication = null): RoutingPathsEnum {
		const loanApp = loanApplication || this.loanAppService.getLoanApp();
		const status = loanApp.applicationStatus;
		const productOfferDetailUtils = ProductOfferDetailsUtils.fromLoanApp(loanApp);

		switch (status) {
			case ApplicationStatusEnum.futureProspect:
				return RoutingPathsEnum.identityConfirm;
			case ApplicationStatusEnum.started: {
				if (
					loanApp.applicationFlow === ApplicationFlowEnum.fastTrackReturning &&
					this.sessionStorageService.get('isFTREnabled') === true
				) {
					const section: FTRSectionNameEnum = FTRSectionRoute.get(this.getRouteStatusStarted(loanApp));
					this.sessionStorageService.set('fastTrackStep', section);
					return RoutingPathsEnum.fastTrack;
				} else {
					return this.getRouteStatusStarted(loanApp);
				}
			}
			case ApplicationStatusEnum.prequalAccepted:
				return this.getRouteStatusPrequalAccepted(loanApp);
			case ApplicationStatusEnum.preApproved:
				return this.getRouteStatusPreApproved(loanApp);
			case ApplicationStatusEnum.bounced:
				return RoutingPathsEnum.documentSubmit;
			case ApplicationStatusEnum.documentsSigned:
				return RoutingPathsEnum.status;
			case ApplicationStatusEnum.disbursed:
				return RoutingPathsEnum.loanCompleted;
			case ApplicationStatusEnum.securedAccepted:
				return RoutingPathsEnum.vehicle;
			case ApplicationStatusEnum.securedDeclined: {
				return productOfferDetailUtils.hasAtleastOneLoan()
					? RoutingPathsEnum.offerStatus
					: RoutingPathsEnum.vehicleEligibility;
			}
			case ApplicationStatusEnum.securedOffered:
				return RoutingPathsEnum.offerStatus;
			case ApplicationStatusEnum.approved:
				return RoutingPathsEnum.offerStatus;
		}
		return RoutingPathsEnum.status;
	}

	getOnlineNotificationStep(loanApp: ILoanApplication, loanTerms: ILoanTermsResult): Observable<RoutingPathsEnum> {
		let applicationStatus = loanApp.applicationStatus;
		const productOfferDetails = ProductOfferDetailsUtils.fromLoanApp(loanApp);
		const disbursementInfo = DisbursementUtils.fromLoanApp(loanApp);

		applicationStatus = this.getAppStatusBasedOnSelectedProduct(loanApp, productOfferDetails, applicationStatus);

		if (
			ApplicationStatusEnum.approved != applicationStatus &&
			ApplicationStatusEnum.termsConfirmed != applicationStatus
		) {
			return of(RoutingPathsEnum.status);
		}

		if (!loanTerms?.termsExpired && !disbursementInfo.isNotificationTermsAdjusted()) {
			return of(RoutingPathsEnum.loanBenefits);
		}

		if (
			disbursementInfo.isNotificationAdditionalContactRequired() &&
			!disbursementInfo.isNotificationAdditionalContactCompleted()
		) {
			return of(RoutingPathsEnum.additionalContact);
		}

		if (
			disbursementInfo.isNotificationSpouseContactRequired() &&
			!disbursementInfo.isNotificationSpouseContactCompleted()
		) {
			return this.onlineNotificationService.isWIFlow(loanApp).pipe(
				map((rsp) => {
					return rsp ? RoutingPathsEnum.spousalContact : RoutingPathsEnum.status;
				})
			);
		}

		return this.routeToConfirmTerms(loanApp, loanTerms);
	}

	private getAppStatusBasedOnSelectedProduct(
		loanApp: ILoanApplication,
		productOfferDetails: ProductOfferDetailsUtils,
		applicationStatus: ApplicationStatusEnum
	) {
		let selectedProductOffer: IProduct;

		const productCategory = this.getProductCategorySelection(loanApp);

		if (ProductCategoriesEnum.unsecuredPersonalLoan === productCategory) {
			selectedProductOffer = productOfferDetails.getUnsecuredPersonalLoanProduct();
		} else if (ProductCategoriesEnum.securedPersonalLoan === productCategory) {
			selectedProductOffer = productOfferDetails.getSecuredPersonalLoanProduct();
		}

		const applicationStatusKeyArray = Object.keys(ApplicationStatusEnum).filter(
			(status) => ApplicationStatusEnum[status] === selectedProductOffer.productStatus
		);

		if (selectedProductOffer?.productStatus && applicationStatusKeyArray?.length > 0) {
			applicationStatus = ApplicationStatusEnum[applicationStatusKeyArray[0]];
		}
		return applicationStatus;
	}

	private verifyBankAccount(
		loanApp: ILoanApplication,
		loanTerms: ILoanTermsResult,
		disbursementInfo: DisbursementUtils
	): Observable<RoutingPathsEnum> {
		return this.onlineNotificationService.verifyBankAccount().pipe(
			map((isVerified: boolean) => {
				if (isVerified) {
					return RoutingPathsEnum.esignAutoPay;
				} else if (
					disbursementInfo.isOnlineNotificationEnabled() &&
					disbursementInfo.isChannelAnyStore() &&
					!this.isTermsConfirmedDone(loanApp, loanTerms)
				) {
					return RoutingPathsEnum.termsConfirmed;
				} else {
					return RoutingPathsEnum.esignSignDocument;
				}
			})
		);
	}

	routeToConfirmTerms(loanApp: ILoanApplication, loanTerms: ILoanTermsResult): Observable<RoutingPathsEnum> {
		if (
			ApplicationStatusEnum.termsConfirmed === loanApp.applicationStatus &&
			!loanTerms?.termsExpired &&
			this.loanAppService.isStepComplete(ApplicantStepCompleteEnum.notification, true)
		) {
			const disbursementInfo = DisbursementUtils.fromLoanApp(loanApp);
			if (disbursementInfo.getDisbursementAccountId() && !disbursementInfo.isNotificationAutoPayCompleted()) {
				return this.verifyBankAccount(loanApp, loanTerms, disbursementInfo);
			} else {
				if (
					disbursementInfo.isOnlineNotificationEnabled() &&
					disbursementInfo.isChannelAnyStore() &&
					!this.isTermsConfirmedDone(loanApp, loanTerms)
				) {
					return of(RoutingPathsEnum.termsConfirmed);
				} else {
					return of(RoutingPathsEnum.esignSignDocument);
				}
			}
		} else {
			return loanTerms?.termsExpired ? of(RoutingPathsEnum.adjustTerms) : of(RoutingPathsEnum.esignConfirmTerm);
		}
	}

	isTermsConfirmedDone(loanApp: ILoanApplication, loanTerms: ILoanTermsResult): boolean {
		const productOfferDetailsUtils = ProductOfferDetailsUtils.fromLoanApp(loanApp);
		let completeSubProduct = productOfferDetailsUtils.findProductSubStatus(
			ProductCategoriesEnum.securedPersonalLoan,
			ProductSubStatusesEnum.complete
		);
		if (
			loanApp.isSplStorelessEligible &&
			completeSubProduct &&
			loanApp.productCategory === ProductCategoriesEnum.securedPersonalLoan &&
			!loanTerms?.termsExpired &&
			loanApp.applicationStatus === ApplicationStatusEnum.termsConfirmed
		) {
			return true;
		}
		return false;
	}

	getProductCategorySelection(loanApp: ILoanApplication): string {
		const loanTermsProductCategory = loanApp.productCategory;
		if (ProductCategoriesEnum.personalLoan === loanTermsProductCategory) {
			return this.sessionStorageService.get('productCategorySelection');
		}
		return loanTermsProductCategory;
	}

	routeForFastTrack(returnToFTR: string): void {
		this.sessionStorageService.set('fastTrackStep', returnToFTR);
		this.route(RoutingPathsEnum.fastTrack);
	}

	hasValidReferralParams(params = null): boolean {
		const queryParams = params || this.activatedRoute.snapshot?.queryParams;
		return queryParams?.trkcid || this.hasValidWUReferralParams(queryParams);
	}

	hasValidWUReferralParams(queryParams): boolean {
		return Boolean(REFERRAL_PARTNER.WESTERN_UNION === queryParams?.Partner?.toUpperCase());
	}
}
