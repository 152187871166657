<div class="status-not-approved" *transloco="let t">
	<op-masthead [title]="t(title)"></op-masthead>
	<op-content-container>
		<div class="op-light-black">{{ t(subTitle) }}</div>
		<div class="op-mt-12">
			<op-money-lion
				*ngIf="moneyLionUrl"
				class="op-mb-20"
				id="moneyLionOffer"
				(OnClickTriggerAction)="onMoneyLion($event)"
			></op-money-lion>

			<hr *ngIf="moneyLionUrl" />
			<div class="op-body-1-font op-primary-black op-mt-16">
				{{ t('STATUS.NOT_APPROVED.reApplyDate', { newApplicationStartDate: newApplicationStartDate }) }}
			</div>
			<div class="op-body-2-font op-light-black op-mt-8 op-mb-20">
				{{ t('STATUS.NOT_APPROVED.mailReason') }}
			</div>

			<op-digit-offer
				*ngIf="digitUrl"
				class="op-mb-20"
				[digitUrl]="digitUrl"
				id="digitOffer"
				(OnClickTriggerAction)="onDigitOffer($event)"
				[isPathward]="issuingOrganizationIsMeta"
			></op-digit-offer>

			<op-unidos-us-information *ngIf="issuingOrganizationIsOportun && digitUrl === null"></op-unidos-us-information>
		</div>
	</op-content-container>
</div>
