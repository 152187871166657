import { Component, Input } from '@angular/core';

/**
 * Horizontal rule with text in the middle.
 *
 * @export
 * @class HorizontalRuleComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'op-horizontal-rule',
	template: ` <div class="text-divider" [ngClass]="{ divider: !textOnly }">{{ text }}</div> `,
	styleUrls: ['./horizontal-rule.component.scss']
})
export class HorizontalRuleComponent {
	@Input() text: string;
	@Input() textOnly: boolean = false;
}
