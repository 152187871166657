<span class="loan-distribution-term op-label-font" *transloco="let t">
	<a (click)="isTermVisible = !isTermVisible" aria-controls="terms-of-loan">
		{{ 'MANUAL_ADD_BANK.termsOfLoanDist' | transloco }}
	</a>
	<p
		*ngIf="isTermVisible && issuingOrganization"
		id="terms-of-loan"
		role="status"
		aria-live="assertive"
		[innerHTML]="t('MANUAL_ADD_BANK.termsOfLoanDistContent.' + issuingOrganization)"
	></p>
</span>
